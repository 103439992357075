import React, { useState } from 'react';
import { Icon } from '../../framework/controls';

const CardMessage = ({ detail }) => {
    const { severity, title, messages } = detail;
    const [expandedItems, setExpandedItems] = useState({});

    const getSeverityConfig = (sev) => {
        switch (sev) {
            case 'e':
                return { class: 's-error', icon: 'exclamation-circle', label: 'Error' };
            case 'd':
                return { class: 's-danger', icon: 'exclamation-circle', label: 'Danger' };
            case 'w':
                return { class: 's-warning', icon: 'exclamation-triangle', label: 'Warning' };
            case 'i':
                return { class: '', icon: 'info-circle', label: 'Info' };
            case 't':
                return { class: '', icon: 'code', label: 'Trace' };
            default:
                return { class: '', icon: 'info-circle', label: 'Info' };
        }
    };

    const toggleExpand = (index) => {
        setExpandedItems(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const severityConfig = getSeverityConfig(severity);

    // Sort messages to put focused items first
    const sortedMessages = [...messages].sort((a, b) => {
        if (a.focus && !b.focus) return -1;
        if (!a.focus && b.focus) return 1;
        return 0;
    });

    return (
        <div className="transfer-card" key={detail.rowId}>
            <div className="transfer-cols">
                <div className="transfer-card-col">
                    <div className={severityConfig.class}>
                        <Icon icon={severityConfig.icon} className='pr-2'/> 
                        <span className="bold">{severityConfig.label} - {title} 

                            {detail.redirect && 
                                <span onClick={() => window.open(`${window.location.origin}${detail.redirect}`, '_blank')}>
                                    <span 
                                        className="ml-2 clickable underline"
                                    >
                                        (View) 
                                    </span>
                                    <Icon icon={'external-link-alt'} className='pl-2 clickable'/>
                                </span>
                            }
                        </span>
                    </div>
                    <ul>
                        {sortedMessages.map((x, i) => (
                            <React.Fragment key={`${detail.rowId}-${i}`}>
                                <li className={x.focus ? 'bold' : ''}>
                                    {x.message} 
                                    {x.subList && x.subList.length > 0 && (
                                        <span 
                                            className="ml-2 clickable underline" 
                                            onClick={() => toggleExpand(i)}
                                        >
                                            {expandedItems[i] ? '(Hide Details)' : '(See Details)'}
                                        </span>
                                    )}
                                </li>
                                {x.subList && (
                                    <div className={`details-wrapper ${expandedItems[i] ? 'expanded' : ''}`}>
                                        <ul className="ml-4">
                                            {x.subList.map((y, j) => (
                                                <li 
                                                    key={`${detail.rowId}-${i}-${j}`}
                                                    className={x.focus ? 'bold' : ''}
                                                >
                                                    {y}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CardMessage;
