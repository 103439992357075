import MemberImportBusiness from "./MemberImportBusiness/MemberImportBusiness";
import MercerKeyImportBusiness from "./MercerKeyImportBusiness/MercerKeyImportBusiness";
import { DOC_LINKS } from "../../utils/documentation";

export default {
	MEMBERS: { 
		type: 'members', 
		label: 'Import Members', 
		importFunction: async (...args) => MemberImportBusiness.import(...args),
		template: () => MemberImportBusiness.getTemplate(),
		messageFactory: () => MemberImportBusiness.getImportMessageFactory(),
		documentation: DOC_LINKS.MEMBER_IMPORT
	},
	MERCER_KEYS: { 
		type: 'mercerKeys', 
		label: 'Import Mercer Keys', 
		importFunction: async (...args) => MercerKeyImportBusiness.import(...args),
		template: () => MercerKeyImportBusiness.getTemplate(),
		messageFactory: () => MercerKeyImportBusiness.getImportMessageFactory(),
		documentation: DOC_LINKS.MERCER_KEY_IMPORT
	}
}