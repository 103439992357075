import React from 'react'
import { Button } from 'react-bootstrap'

export default class MyButton extends React.Component {
    render() {
        const { className, variant, onClick, text, type, disabled, startIcon, tooltip } = this.props;
 		const tooltipPosition = this.props['tooltip-left'] ? 's-tooltip-left' : '';
        if (variant) {
            return (
                <Button variant='link' className={className} onClick={onClick.bind(this)} disabled={disabled}>
                   {tooltip && <div className={["s-tooltip", tooltipPosition ].join(' ')}>{ tooltip }</div>}   
                     <i className={"fas fa-" + variant + "-circle"}></i>
                </Button>   
            )   
        } 
        return (
            <Button variant={type} className={className} disabled={disabled} onClick={onClick} onMouseLeave={this.props.onMouseLeave}>
                 {tooltip && <div className={["s-tooltip", tooltipPosition ].join(' ')}>{ tooltip }</div>}
                 {startIcon ? <><i className={"fas fa-" + startIcon}/>{" "}</> : null}{text || this.props.children}
             </Button>
        )
    }
}