import React, { useEffect, useState } from 'react'
import { Table, Button, Icon } from '../../framework/controls'
import { Row, Col } from '../../framework/containers'
import { Excel, Period } from '../../framework/utils'
import { moment, formatSIN, LONG_FORMAT, DATE_FORMAT } from '../../framework/utils/helper'
import { EarningType } from '../../entities'

import { RemittanceDetail } from '../../entities'
import { YearEndService, RemittanceDetailService, AdjustmentService } from '../../services'
import CustomDropdown from '../../components/form/Dropdown'
import { renderAmountEmptyFor0, renderComponent, renderNumberEmptyFor0, renderToString } from '../../framework/utils/renders'
import Loading from '../../components/containers/Loading'
import { formatAmount } from '../../framework/utils/formating'

const EmploymentFinancialTable = ({ employment, tabName }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [remittanceDetails, setRemittanceDetails] = useState();
    const [year, setYear] = useState("all");
    const isContributions = tabName === "contributions";
    const isEarnings = tabName === "earnings";
    const isDeemedEarnings = tabName === 'deemedEarnings';
    const isCreditedService = tabName === "creditedService";
    const yearOptions = [
        { text: "All", value: "all" },
        ...Period.getYears(undefined, undefined).map((option) => ({
            text: option.year,
            value: option.year,
        })),
    ];

    useEffect(() => {
        
        let isMounted = true;
        RemittanceDetailService.loadDetailsWithAdjustmentsForEmployment(employment, {excludeRetroactiveAdjustments: true}).then(details => {
            details.setYtps();
            details.setYMPEReached();
            if (isMounted) {
                setRemittanceDetails(details);
                setIsLoading(false);
            }
        });

        return () => { isMounted = false };
    }, []);
    
    const renderYMPEIcon = (value, instance) =>
        renderComponent(
            value && value !== 0 ? (
                <div className={value < 0 ? "text-danger" : ""}>
                    {formatAmount(value, "")}
                    {instance.isYMPEReached && (
                        <Icon
                            icon="exclamation-triangle"
                            large
                            tooltip="YMPE was reached"
                            tooltip-left
                            className="text-warning ml-2"
                        />
                    )}
                </div>
            ) : (
                <></>
            )
        );

    const renderExcelYMPEIcon = (value, instance) => {
        return instance.isYMPEReached
            ? `$${value} (YMPE REACHED)`
            : `$${value}`;
    }

    const renderContributions = (value, instance, cell) => {
        const columnNames = cell.getField().split('.');
        const isHours = columnNames[1].toLowerCase().includes("hours"); 
        const adjType = columnNames[0] === 'sumContributions' ? instance.adjustmentContributions : instance.adjustmentEarnings;
        const adjustedValue = value;

        if (
            adjType[columnNames[1]] === 0 ||
            columnNames[1] === "total"
        ) {
            return isHours 
                ? renderNumberEmptyFor0(adjustedValue)
                : renderAmountEmptyFor0(adjustedValue);
        }

        return renderComponent(
            <div className={adjustedValue < 0 ? "text-danger" : ""}>
                {isHours ? adjustedValue : formatAmount(adjustedValue, "")}
                <Icon
                    tooltip="An adjustment is added to this amount"
                    icon="calculator"
                    className="s-warning ml-2"
                />
            </div>
        );
    }

    const getColumnString = () => {
        let columnString = "";

        if (isContributions) {
            columnString = 
                "period, sumContributions.reg, sumContributions.mat, sumContributions.ltd, sumContributions.slf, sumContributions.vol, retroContributionAdjustments.totalDetail, sumContributions.total, ytdContributions.reg, ytdContributions.mat, ytdContributions.ltd, ytdContributions.slf, ytdContributions.vol, ytdContributions.total, reviewedCmt, reasonDaysDiff, confStatus, finalPayOut, personalDataERInitialsCmt, statusConfirmationCmt, statusERInitialsCmt, memtdConfirmationCmt, memtdERInitialsCmt, maritalStatusERInitialsCmt, peERInitialsCmt, contERInitialsCmt, phERInitialsCmt, oneTimeConfirmationCmt, variationERInitialsCmt";
        } else if (isEarnings) {
            columnString =
                "period, sumEarnings.regular, sumEarnings.overtime, sumEarnings.oneTime, sumEarnings.deemed, sumEarnings.pensionable, sumEarnings.nonPensionable, sumEarnings.total, ytdEarnings.regular, ytdEarnings.overtime, ytdEarnings.oneTime, ytdEarnings.deemed,ytdEarnings.pensionable, ytdEarnings.total, reviewedCmt, reasonDaysDiff, confStatus, finalPayOut, personalDataERInitialsCmt, statusConfirmationCmt, statusERInitialsCmt, memtdConfirmationCmt, memtdERInitialsCmt, maritalStatusERInitialsCmt, peERInitialsCmt, contERInitialsCmt, phERInitialsCmt, oneTimeConfirmationCmt, variationERInitialsCmt";
        } else if (isDeemedEarnings) {
            columnString =
                "period, totalMatEarningsAmount, totalMatEarningsHours, totalLTDEarningsAmount, totalLTDEarningsHours, totalSelfEarningsAmount, totalSelfEarningsHours, sumEarnings.deductable, sumEarnings.deductableHours, creditedService, ytdCreditedService";
        } else if (isCreditedService) {
            columnString =
                "period, sumEarnings.hours, sumEarnings.regularHours, sumEarnings.overtimeHours, sumEarnings.deemedHours, sumEarnings.deductableHours, sumEarnings.deductableHours, creditedService, ytdCreditedService, workSchedule.monthlySchedule, workedDays";
        }

        return columnString;
    }

    let columns = new Table.Headers(
        RemittanceDetail,
        "period, sumContributions.reg, sumContributions.mat, sumContributions.ltd, sumContributions.slf, sumContributions.vol, retroContributionAdjustments.totalDetail, sumContributions.total, ytdContributions.reg, ytdContributions.mat, ytdContributions.ltd, ytdContributions.slf, ytdContributions.vol, ytdContributions.total, reviewedCmt, reasonDaysDiff, confStatus, finalPayOut, personalDataERInitialsCmt, statusConfirmationCmt, statusERInitialsCmt, memtdConfirmationCmt, memtdERInitialsCmt, maritalStatusERInitialsCmt, peERInitialsCmt, contERInitialsCmt, phERInitialsCmt, oneTimeConfirmationCmt, variationERInitialsCmt"
    );

    if (isContributions) {
        columns.list.filter(header => String(header.name).startsWith('sumContributions')).forEach(header => columns[header.name].format = renderContributions)
        columns["retroContributionAdjustments.totalDetail"].title = "Prior Year Retro";
        columns["sumContributions.total"].title = "Total";
        columns["sumContributions.total"].className = "table-col-separator";
        columns["ytdContributions.reg"].title = "YTD Regular";
        columns["ytdContributions.mat"].title = "YTD  Maternity";
        columns["ytdContributions.ltd"].title = "YTD LTD";
        columns["ytdContributions.slf"].title = "YTD Self";
        columns["ytdContributions.vol"].title = "YTD Voluntary";
        columns["ytdContributions.total"].title = "YTD Total";
        columns["ytdContributions.total"].className = "table-col-separator";
        columns["ytdContributions.total"].format = renderYMPEIcon;
        columns["ytdContributions.total"].minWidth = 185;
        columns["period"].minWidth = 100;
        columns["reviewedCmt"].minWidth = 200;
        columns["reviewedCmt"].hideIfEmpty = true;

        const commentsName = ['reasonDaysDiff', 'confStatus', 'finalPayOut',
            'personalDataERInitialsCmt', 'statusConfirmationCmt', 'statusERInitialsCmt', 'memtdConfirmationCmt', 'memtdERInitialsCmt', 'maritalStatusERInitialsCmt', 'peERInitialsCmt', 'contERInitialsCmt', 'phERInitialsCmt', 'oneTimeConfirmationCmt', 'variationERInitialsCmt'
        ];
        for (const commentName of commentsName){
            if(columns[commentName]) columns[commentName].minWidth = 400;
            if(columns[commentName]) columns[commentName].hideIfEmpty = true;
            // eslint-disable-next-line no-loop-func
            if(columns[commentName]) columns[commentName].format = (value, instance) => {
                // Needed because all comments are defined as STRING in RemittanceDetail.definitions
                // but some of them might actually be dates, so we try to format them as date if possible
                // if their definition is changed to DATE, this code can be removed
                // See src/entities/pension/RemittanceDetail.js
                return (value && moment.utc(value).isValid()) ? moment.utc(value).format(DATE_FORMAT) : value;
            };
        }
    } else if (isEarnings) {
        columns = new Table.Headers(
            RemittanceDetail,
            "period, sumEarnings.regular, sumEarnings.overtime, sumEarnings.oneTime, sumEarnings.deemed, sumEarnings.pensionable, sumEarnings.nonPensionable, sumEarnings.total, ytdEarnings.regular, ytdEarnings.overtime, ytdEarnings.oneTime, ytdEarnings.deemed,ytdEarnings.pensionable, ytdEarnings.total, reviewedCmt"
        );
        columns.list.filter(header => String(header.name).startsWith('sumEarnings')).forEach(header => columns[header.name].format = renderContributions)
        columns["sumEarnings.total"].title = "Total";
        columns["sumEarnings.total"].className = "table-col-separator";
        columns["ytdEarnings.regular"].title = "YTD Regular";
        columns["ytdEarnings.overtime"].title = "YTD  Overtime";
        columns["ytdEarnings.oneTime"].title = "YTD One Time";
        columns["ytdEarnings.deemed"].title = "YTD Deemed";
        columns["ytdEarnings.pensionable"].title = "YTD Pensionable";
        columns["ytdEarnings.pensionable"].format = renderYMPEIcon;
        columns["ytdEarnings.pensionable"].minWidth = 185;
        columns["ytdEarnings.total"].title = "YTD Total";
        columns["ytdEarnings.total"].className = "table-col-separator";
        columns["period"].minWidth = 100;
        columns["reviewedCmt"].minWidth = 200;
        columns["reviewedCmt"].hideIfEmpty = true;

        const commentsName = ['reasonDaysDiff', 'confStatus', 'finalPayOut',
            'personalDataERInitialsCmt', 'statusConfirmationCmt', 'statusERInitialsCmt', 'memtdConfirmationCmt', 'memtdERInitialsCmt', 'maritalStatusERInitialsCmt', 'peERInitialsCmt', 'contERInitialsCmt', 'phERInitialsCmt', 'oneTimeConfirmationCmt', 'variationERInitialsCmt'
        ];
        for (const commentName of commentsName){
            if(columns[commentName]) columns[commentName].minWidth = 400;
            if(columns[commentName]) columns[commentName].hideIfEmpty = true;
            // eslint-disable-next-line no-loop-func
            if(columns[commentName]) columns[commentName].format = (value, instance) => {
                // Needed because all comments are defined as STRING in RemittanceDetail.definitions
                // but some of them might actually be dates, so we try to format them as date if possible
                // if their definition is changed to DATE, this code can be removed
                // See src/entities/pension/RemittanceDetail.js
                return (value && moment.utc(value).isValid()) ? moment.utc(value).format(DATE_FORMAT) : value;
            };
        }
    } else if (isDeemedEarnings) {
        columns = new Table.Headers(
            RemittanceDetail,
            "period, totalMatEarningsAmount, totalMatEarningsHours, totalLTDEarningsAmount, totalLTDEarningsHours, totalSelfEarningsAmount, totalSelfEarningsHours, sumEarnings.deductable, sumEarnings.deductableHours, creditedService, ytdCreditedService"
        );
        columns["totalMatEarningsAmount"].title = "Maternity Amount";
        columns["totalMatEarningsAmount"].minWidth = 180;
        columns["totalMatEarningsHours"].title = "Maternity Hours";
        columns["totalMatEarningsHours"].minWidth = 162;
        columns["totalLTDEarningsAmount"].title = "LTD Amount";
        columns["totalLTDEarningsAmount"].minWidth = 134;
        columns["totalLTDEarningsHours"].title = "LTD Hours";
        columns["totalLTDEarningsHours"].minWidth = 120;
        columns["totalSelfEarningsAmount"].title = "Self Amount";
        columns["totalSelfEarningsAmount"].minWidth = 198;
        columns["totalSelfEarningsHours"].title = "Self Hours";
        columns["totalSelfEarningsHours"].minWidth = 185;
        columns["creditedService"].minWidth = 170;
        columns["ytdCreditedService"].minWidth = 202;
        columns["period"].minWidth = 100;
        columns["sumEarnings.deductable"].title =
            "Total Earnings (excludes deemed)";
        columns["sumEarnings.deductable"].minWidth = 162;
        columns["sumEarnings.deductableHours"].title =
            "Total Hours (excludes deemed)";
        columns["sumEarnings.deductableHours"].minWidth = 142;
    } else if (isCreditedService) {
        columns = new Table.Headers(
            RemittanceDetail,
            "period, sumEarnings.hours, sumEarnings.regularHours, sumEarnings.overtimeHours, sumEarnings.deemedHours, sumEarnings.deductableHours, sumEarnings.deductableHours, creditedService, ytdCreditedService, workSchedule.monthlySchedule, workedDays, activeDays"
        );
        columns.list.filter(header => String(header.name).startsWith('sumEarnings')).forEach(header => columns[header.name].format = renderContributions)
        columns["sumEarnings.hours"].title = "Total Hours";
        columns["sumEarnings.regularHours"].title = "Regular Hours";
        columns["sumEarnings.overtimeHours"].title = "Overtime Hours";
        columns["sumEarnings.deemedHours"].title = "Deemed Hours";
        columns["sumEarnings.deductableHours"].title = "Deductable Hours";
        columns["period"].minWidth = 90;
        columns["sumEarnings.hours"].minWidth = 126;
        columns["sumEarnings.regularHours"].minWidth = 150;
        columns["sumEarnings.overtimeHours"].minWidth = 160;
        columns["sumEarnings.deemedHours"].minWidth = 150;
        columns["sumEarnings.deductableHours"].minWidth = 175;
        columns["creditedService"].minWidth = 164;
        columns["ytdCreditedService"].minWidth = 204;
        columns["workSchedule.monthlySchedule"].minWidth = 222;
        columns["workedDays"].title = "Progressive Return Days";
        columns["workedDays"].minWidth = 224;
        columns["activeDays"].minWidth = 224;
    }

    const handleDownloadData = () => {
        const excelHeaders = new Excel.Headers(
            RemittanceDetail, 
            getColumnString(),
        );
        const excel = new Excel(
            `Employee-${employment.person.lastName}-${employment.person.firstName}-${tabName}-${year}.xlsx`
        );
        if (isContributions) {
            excelHeaders["ytdContributions.total"].format = renderExcelYMPEIcon;
        } else if (isEarnings) {
            excelHeaders["ytdEarnings.pensionable"].format =
                renderExcelYMPEIcon;
            excelHeaders["sumEarnings.total"].title = "Total";
            excelHeaders["ytdEarnings.regular"].title = "YTD Regular";
            excelHeaders["ytdEarnings.overtime"].title = "YTD  Overtime";
            excelHeaders["ytdEarnings.oneTime"].title = "YTD One Time";
            excelHeaders["ytdEarnings.deemed"].title = "YTD Deemed";
            excelHeaders["ytdEarnings.pensionable"].title = "YTD Pensionable";
            excelHeaders["ytdEarnings.total"].title = "YTD Total";
        } else if (isDeemedEarnings) {
            excelHeaders["totalMatEarningsAmount"].title = "Maternity Amount";
            excelHeaders["totalMatEarningsHours"].title = "Maternity Hours";
            excelHeaders["totalLTDEarningsAmount"].title = "LTD Amount";
            excelHeaders["totalLTDEarningsHours"].title = "LTD Hours";
            excelHeaders["totalSelfEarningsAmount"].title = "Self Amount";
            excelHeaders["totalSelfEarningsHours"].title = "Self Hours";
        }
        excel.addSheet(
            excelHeaders.list,
            year !== "all"
                ? remittanceDetails.filter((rem) =>
                      rem.periodText.includes(year)
                  )
                : remittanceDetails.all
        );
        excel.download();
    };

    const handleTerminationReport = async () => {
        const headers = YearEndService.initHeader(
            YearEndService.displayTypes.TERM,
            Excel,
            EarningType.splitEarningsTypesByCategory(
                employment.employer.getActiveEarningTypes()
            )
        );

        const contributionsData = {
            name: employment?.person.name,
            sin: formatSIN(employment?.person.sin),
        };
        const excel = new Excel(`Employee-${employment.person.lastName}${employment.person.firstName}-TerminationReport.xlsx`);
        const terminatedYear = (terminationEvent?.effMoment ?? moment()).year() - 1;

        var details = [];
        for (var year = terminatedYear; year < terminatedYear + 3; year++) {
            var results = await YearEndService.getData({ employment, year: year.toString(), allowNoYearEnds: true, useDefaultSpouse: false });
            
            if (results?.details && results.details.length > 0) {
                details = [...details, ...results.details];
            } else {
                details.push({}); //we want an empty column
            }
        }
        const monthsYearsData = remittanceDetails?.map(x => ({
            totalEmployeeContributions: x.totalEmployeeContributions, // 314.89
            regularContributions: x.sumContributions.reg,
            maternityContributions: x.sumContributions.mat,
            ltdContributions: x.sumContributions.ltd,
            selfContributions: x.sumContributions.slf,
            voluntaryContributions: x.sumContributions.vol,
            retroContributions: x.retroContributionAdjustments?.totalDetail || 0,
            periodText: x.periodText, // "2019/01"
            periodYear: x.period?.year, // "2019"
            periodMonth: x.period?.month, // "01"
            ytdContributionsTotal: x.ytdContributions?.total || 0, // 4341.48
            ytdContributions: x.ytdContributions,
        }));
        const yearsSet = new Set();
        const currentYear = new Date().getFullYear();
        // ensure there are all years between currentYear and oldest year
        for (let year = Math.min(...monthsYearsData.filter(x => x.periodYear).map(x => Number(x.periodYear))); year <= currentYear; year++) {
            yearsSet.add(year);
        }

        excel.transpose(excel.addSheet(headers, details, "Financial Summary"));

        /** Rows of the Contributions sheet 
         * @type Array<{row: Array<string | number>; isBold?: boolean, mergeCols?: number;, boldCols?: number[]}>*/
        const contributionsRows = [];
        const contributionBuckets = ['Regular', 'Maternity', 'Long Term', 'Self', 'Voluntary', 'Retro'];
        const yearsArray = Array.from(yearsSet);
        yearsArray.sort().reverse();
        // merge the title (cols: Labels cols + all years cols). 0-based index
        const lastColIndex = 1 + yearsSet.size - 1;
        contributionsRows.push({row: ["Total Contribution Summary"], isBold: true, mergeCols: lastColIndex + 1}); // 1
        contributionsRows.push({row: ["Employer:", `${employment?.employer?.code || ''} - ${employment?.employer?.name || ''}`], isBold: true}); // 2
        contributionsRows.push({row: ["Period:", `${employment?.participation?.joinDt ? moment(employment.participation.joinDt).format('DD MMM YYYY') : ''} - ${moment(new Date()).format('DD MMM YYYY')}`], isBold: true}); // 3
        contributionsRows.push({row: ["Employee name:", contributionsData.name], isBold: false}); // 4
        contributionsRows.push({row: ["SIN:", contributionsData.sin], isBold: false}); // 5
        contributionsRows.push({row: ["Generated Date:", moment(new Date()).format('DD MMM YYYY')], isBold: false}); // 6
        contributionsRows.push({row: [], isBold: false}); // 7
        contributionsRows.push({row: ['Month', yearsArray[0]], isBold: true}); // 8
        contributionsRows.push({row: ['', ...contributionBuckets, ...yearsArray.slice(1).map(year => "Total Contributions")], isBold: true}); // 9

        // months 1 to 12
        for (let monthIndex = 1; monthIndex <= 12; monthIndex++) {
            // all years for the current month, ordered by year desc
            const monthData = yearsArray.map(year => 
                    monthsYearsData.find(x => Number(x.periodMonth) === monthIndex && Number(x.periodYear) === Number(year)));
            const monthContributions = monthData.map((data, index) => {
                const isLatestYear = index === 0;
                let contributionColumns = data?.totalEmployeeContributions || 0;

                // Add contribution buckets to latest year
                if (isLatestYear) {
                    contributionColumns = [
                        data?.regularContributions || 0,
                        data?.maternityContributions || 0,
                        data?.ltdContributions || 0,
                        data?.selfContributions || 0,
                        data?.voluntaryContributions || 0,
                        data?.retroContributions || 0,
                    ];
                }
                return contributionColumns;
            }).flat();
            contributionsRows.push({row: [`${monthIndex}`, ...monthContributions], isBold: false});
        }

        // "year end" month: periodYear == periodMonth
        const yeMonthData = yearsArray.map(year => 
            monthsYearsData.find(x => Number(x.periodMonth) ===  Number(x.periodYear) && Number(x.periodYear) === Number(year)));
        const yeMonthContributions = yeMonthData.map((data, index) => {
            const isLatestYear = index === 0;
                let contributionColumns = data?.totalEmployeeContributions || 0;

                // Add contribution buckets to latest year
                if (isLatestYear) {
                    contributionColumns = [
                        data?.regularContributions || 0,
                        data?.maternityContributions || 0,
                        data?.ltdContributions || 0,
                        data?.selfContributions || 0,
                        data?.voluntaryContributions || 0,
                        data?.retroContributions || 0,
                    ];
                }
                return contributionColumns;
        }).flat();
        contributionsRows.push({row: ['YE', ...yeMonthContributions], isBold: false});
        contributionsRows.push({row: [], isBold: false});

        // Total row
        // sum for each year, use the ytdContributionsTotal of the "year end" month
        const yearsTotals = yearsArray.map((year, index) => {
            const isLatestYear = index === 0;
            const ytdData = monthsYearsData.find(x => Number(x.periodMonth) ===  Number(x.periodYear) && Number(x.periodYear) === Number(year))
            let totalData = ytdData?.ytdContributionsTotal || 0;

            // Add contribution buckets to latest year
            if (isLatestYear) {
                // Calculate total retro contributions
                const yearYtdData = monthsYearsData.filter(x => Number(x.periodYear) === Number(year)).reduce((total, data) => total + (data?.retroContributions || 0), 0);

                totalData = [
                    ytdData?.ytdContributions.reg || 0,
                    ytdData?.ytdContributions.mat || 0,
                    ytdData?.ytdContributions.ltd || 0,
                    ytdData?.ytdContributions.slf || 0,
                    ytdData?.ytdContributions.vol || 0,
                    yearYtdData
                ];
            }
            return totalData;
        }).flat();
        contributionsRows.push({row: ['Total', ...yearsTotals], isBold: true});

        let contributionSheet = excel.addContributionsSheet(contributionsRows);

        // Total retro
        // Find the row with the total contributions and get row index
        const totalRowIndex = contributionSheet._rows.find(x => x.values[1] === 'Total')?.number;

        // Row with year headers
        const yearHeaderRowIndex = contributionSheet._rows.find(x => x.values[1] === 'Month')?.number;
        const bucketsRow = contributionSheet._rows.find(x => x.values[2] === contributionBuckets[0]);
        const latestYearBucketStartCol = bucketsRow._cells.find(cell => cell.value === contributionBuckets[0])?.col; 
        contributionSheet.mergeCells(yearHeaderRowIndex, latestYearBucketStartCol, yearHeaderRowIndex, latestYearBucketStartCol + contributionBuckets.length - 1);

        // Add year header values to the row (other than the latest year)
        const yearRow = contributionSheet.getRow(yearHeaderRowIndex);
        for (let i = 1; i < yearsArray.length; i++) {
            yearRow.getCell(i + contributionBuckets.length + 1).value = yearsArray[i];
            yearRow.getCell(i + contributionBuckets.length + 1).font = { bold: true };
        }

        // Add borders around the contribution buckets for the latest year
        excel.createBorder(contributionSheet, 
            {row: yearHeaderRowIndex, col: latestYearBucketStartCol}, // start cell
            {row: totalRowIndex, col: latestYearBucketStartCol + contributionBuckets.length - 1} // end cell
        );


        const lastDetail = details?.filter(x => x.detailKey)?.length ? details[details?.filter(x => x.detailKey)?.length-1] : undefined;
        const flagsData = lastDetail?.isN ? lastDetail : employment?.person?.isN ? employment.person : employment?.isN ? employment : undefined;
        const personalInfoData = {
            sin: formatSIN(employment?.person.sin),
            firstName: employment?.person?.firstName,
            lastName: employment?.person?.lastName,
            dob: employment?.person?.dob,
            address_poBox: employment?.person?.address?.poBox,
            address_civic: employment?.person?.address?.civic,
            address_city: employment?.person?.address?.city,
            address_province: employment?.person?.address?.prov,
            address_postalCode: employment?.person?.address?.pc,
            address_country: employment?.person?.address?.country,
            emails: employment?.person?.emails?.all?.map((emailData, i) => ([`Email ${i + 1}`, emailData.email, `Usage ${i + 1}`, emailData.usage, ''])) ?? [],
            phones: employment?.person?.phones?.all?.map((phoneData, i) => ([`Phone ${i + 1}`, phoneData.number, `Usage ${i + 1}`, phoneData.usage, ''])) ?? [],
            gender: employment?.person?.gender,
            language: employment?.person?.lng === 'fr' ? 'French' : employment?.person?.lng === 'en' ? 'English' : '',
            native: flagsData?.isN === 'n' ? 'No' : flagsData?.isN === 'y' ? 'Yes' : '',
            cpp_qpp: flagsData?.isCQ === 'n' ? 'No' : flagsData?.isCQ === 'y' ? 'Yes' : '',
            taxPayer: flagsData?.isTP === 'n' ? 'No' : flagsData?.isTP === 'y' ? 'Yes' : '',
        };
        /** Rows of the Personal Info sheet 
         * @type Array<{row: Array<string | number>; isBold?: boolean, mergeCols?: number;, boldCols?: number[]}>*/
        const personalInfoRows = [
            {row: ['Code', employment?.employer?.code], boldCols: [0]},
            {row: ['Juridiction', employment?.employer?.jurisdictionCode === 'que' ? 'Quebec' : 'Federal' ], boldCols: [0]},
            {row: ['SIN', personalInfoData.sin], boldCols: [0]},
            {row: ['First Name',personalInfoData.firstName], boldCols: [0]},
            {row: ['Last Name', personalInfoData.lastName], boldCols: [0]},
            {row: ['Date of Birth', personalInfoData.dob], boldCols: [0]},
            {row: ['P.O. Box', personalInfoData.address_poBox], boldCols: [0]},
            {row: ['Civic', personalInfoData.address_civic], boldCols: [0]},
            {row: ['City', personalInfoData.address_city], boldCols: [0]},
            {row: ['Province', personalInfoData.address_province], boldCols: [0]},
            {row: ['Postal Code', personalInfoData.address_postalCode], boldCols: [0]},
            {row: ['Country', personalInfoData.address_country], boldCols: [0]},
            {row: [...personalInfoData.emails.flat()], boldCols: [...personalInfoData.emails.map((x, i) => [i*5 + 0, i*5 + 2]).flat()]},
            {row: [...personalInfoData.phones.flat()],  boldCols: [...personalInfoData.phones.map((x, i) => [i*5 + 0, i*5 + 2]).flat()]},
            {row: ['Gender', personalInfoData.gender], boldCols: [0]},
            {row: ['Language', personalInfoData.language], boldCols: [0]},
            {row: ['Native', personalInfoData.native], boldCols: [0]},
            {row: ['CPP/QPP', personalInfoData.cpp_qpp], boldCols: [0]},
            {row: ['Tax Payer', personalInfoData.taxPayer], boldCols: [0]},
        ];

        excel.addPersonalInfoSheet(personalInfoRows);

        excel.download();
    };

    const terminationEvent = employment.getTerminationEvent();

    return (
        <>
            {isLoading && <Loading />}
            {remittanceDetails && !isLoading && (
                <>
                    <Row>
                        <Col>
                            <CustomDropdown
                                buttonText={`Year: ${year}`}
                                options={yearOptions}
                                onSelect={(value) => setYear(value)}
                            />
                        </Col>
                        <Col right>
                            <Button
                                type="link"
                                className="text-primary"
                                onClick={handleDownloadData}
                            >
                                Export table
                            </Button>
                            <Button
                                type="link"
                                className="text-primary"
                                onClick={handleTerminationReport}
                            >
                                {terminationEvent
                                    ? "Export Termination Report"
                                    : "Financial Summary Export"}
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        id="employee-remittances-details"
                        data={
                            year !== "all"
                                ? remittanceDetails.filter((rem) =>
                                      rem.periodText.includes(year)
                                  )
                                : remittanceDetails.all
                        }
                        entity={RemittanceDetail}
                        columns={columns}
                    />
                </>
            )}
        </>
    );
};

export default EmploymentFinancialTable;
