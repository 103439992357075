/**
 * Represents a field definition for import operations
 */
class ImportFieldDefinition {
    constructor(name, title, options = {}) {
        this.name = name;
        this.title = title;
        this.aliases = options.aliases || [];
        this.format = options.format || (val => val);
        this.validation = options.validation || null;
        this.required = options.required || false;
    }

    /**
     * Validates a value using the field's validation function
     * @param {*} value - The value to validate
     * @returns {ImportFieldValidationResponse} The validation response
     */
    validate(value) {
        if (this.validation) {
            return this.validation(value);
        }
        return null;
    }

    /**
     * Formats a value using the field's format function
     * @param {*} value - The value to format
     * @returns {*} The formatted value
     */
    formatValue(value) {
        if (this.format) {
            return this.format(value);
        }
        return value;
    }
}

export default ImportFieldDefinition;
