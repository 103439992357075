import { Ref, Definition, RefHistorical, RefList } from '../../../framework/infra'
import { ETS_FORMAT, moment } from '../../../framework/utils/helper'
import Payout from './Payout'
import ParticipationPension from './PartcipationPension'
import ParticipationMessages from './ParticipationMessages'
import ParticipationMessage from './ParticipationMessage'
import ParticipationEvents from './ParticipationEvents'
import ParticipationEvent from './ParticipationEvent'
import ParticipationStatus from './ParticipationStatus'
import ParticipationTasks from './ParticipationTasks'
import { ParticipationTaskConfig } from './ParticipationTaskConfig'
import { SubEventConfig } from '../../flow/SubEventConfig'

const { STRING, DATE, BOOLEAN } = Definition.types

export default class Participation extends Ref {
    /**
     * List of employer ID of this participation's employments
     * @type {RefList | undefined}
     */
    employers;

    get person() { return this.membership.person }

    get eventStatuses() { 
        const filteredEvents = this.events.getFiltered(eve => eve.config.status);
        filteredEvents.sortEvents();
        return filteredEvents;
    }
    get closeDate() { return this.events.find((event) => event.status.isClose() || event.config.isPendingEvent)?.ets }
    get latestStatus() { return this.eventStatuses.findLast(eve => eve.config.sts) || new ParticipationEvent()} //remove
    get lastStatusEvent() { return this.eventStatuses.last || new ParticipationEvent({code: 'newPot', effDt: null})}
    get status() { return this.lastStatusEvent.status } //remove
    get statusDesc() { return this.events.statusDesc }
    get message() { return this.messages.last }
    get joinDt() {
        const actEvent = this.getActiveStatusEvent()
        return actEvent ? actEvent.effDt : ''
    }

    /**
     * events with code `metEligDate` (ELIG - Meets eligibility on this date)
     */
    get eligibilities(){
        return this.events.getFiltered(eve => eve.code === 'metEligDate');
    }
    
    get firstEligibility(){ return this.eligibilities.first?.effDt }
    get firstEligibilityDesc(){ 
        const firstEligEvent = this.eligibilities.first;
        if (firstEligEvent) {
            const employer = firstEligEvent.config?.getEmployer(firstEligEvent);
            return moment(firstEligEvent.ets).format(ETS_FORMAT) + (employer ? ` (${employer.code})` : '');
        }
    }
    get lastEligibility(){ return this.eligibilities.last?.effDt }

    get combinedWarnings() { return RefHistorical.combine([this.messages, this.tasks]) }
    /**
     * Participation number label. Can be a number (like "3") or a letter (like "P").
     * The value will be "C" if the last status event is Cancelled, "NE" if the last status event is Ineligible, 
     * or "P" if the last status event is Potential, 
     * otherwise it will be the `reorderedPpNo` value if it exists, or the `no` value.
     */
    get ppNo() {
        if (this.lastStatusEvent.status.isCancelled()) return 'C';
        if (this.lastStatusEvent.status.isIneligible()) return 'NE';
        if (this.lastStatusEvent.status.isPotential()) return 'P';
        return this.reorderedPpNo || this.no
    }

    /**
     * True if this participation's has multiple employments
     * @type {boolean | Employment}
     */
    get isMER(){
        return this.employers.length > 1 || this.employments._list.length > 1 || this.employments.find(e=>e.events.find(x=> x.config.isMultipleEmployer));
    }
    getPotentialEvent() { return this.events.find(e => e.config.isNewPotential)};

    /**
     * Participation has `formReceived` events: `enrRec` (Enrollment form received) or `rcvEnrlFrm` (Enrolment form received)
     * 
     * since old forms were not sub events, we need a legacy function
     * @returns 
     */
    hasLegacyEnrollmentForm () {
        return this.events.all.find(x=>x.config.formReceived);
    }
    hasEnrollmentForm() { 
        //enRec and penExpCan means we either received a form, or the pen was cancelled
        if (this.hasLegacyEnrollmentForm()) return true;
        if (this.events.all.find(x=>x.subEvent.find(x=>x.config.formReceived))) return true;
    } 
    /**
     * 
     * @returns true if:
     * - the member is over age 60
     * - and the last status event is Deferred: `DFR` (Deferred)
     */
    isDeferredOverAge60() { 
        //ASK is it okay if we have one event or it should be the last event?
        return this.person.isOverAge(60) && this.lastStatusEvent.status.isDeferred()
    }
    /**
     * Check if the member is over age, or is turning in the next X months, and if the participation status is not ignored (ignored when close or NOP or not "pending but not close")
     * @param {number} age The age, in years.
     * @param {number} inMonths 
     * @returns {boolean} true if:
     * - the member is over the age or is turning the age in x months
     * - and the participation status is not close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
     * - and the participation does not have a `isNOP` event: `a60Nop` (Age60: NOP - Active60 Declaration received: continue accruing) or `a60neg` (Age60: NOP - Member not entitled to Pension and work at age 60. Data confirmed by employer.) or `l60Nop` (LTD60: NOP - LTD60 Chose to continue LTD)
     * - and the participation is not "pending but not close": not: participation has a pending event `penDea` (Pending - Deceased) or `penTrm` (Pending - Terminated) or `penPnr` (Pending - Pensioner), and participation status is not close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
     */
    isTurningAgeInNextMonths(age, inMonths) { 
        // is over age, or is turning in the next X months
        const isRelevant = this.person.isTurningAgeInNextMonths(age, inMonths) || this.person.isOverAge(age);
        const dontIgnore = (!this.lastStatusEvent.status.isClose() && !this.events.find(ev => ev.config.isNOP)) && !this.isPendingClose() 
        return isRelevant && dontIgnore
    }
    /**
     * 
     * @param {boolean | undefined} includeFollowUp Flag to include the "Latest package follow up" condition (in addition to the "package sent" condition). Default: false.
     * @returns true if the last event date is more than 180 days ago (~ 6 months), and:
     * - the last event is `isPackageSent` (if not `includeFollowUp`): `a60oso` (Age 60: OSO - Outstanding Age60 Options. Package mailed by Mercer', 'Age60 Options. Package mailed by Mercer),
     * `l60oso` (LTD 60: OSO - Outstanding LTD60 Options. Package mailed by Mercer), `penOpo` (Retirement: OPO - Outstanding Pension Options. Options mailed by Mercer),
     * `trmPre` (Termination: OTR - Options package mailed by Mercer: entitled to refund), `trmPde` (Termination: OTD - Options package mailed by Mercer: entitled to deferred),
     * `trmOtn` (Termination: OTDN - Options package mailed by Mercer: entitled to a deferred with no additional benefit),
     * `trmPda` (Termination: OTDA - Options package mailed by Mercer: entitled to a deferred and additional benefit),
     * `deaOdo` (Death: ODO - Outstanding Deceased Options. Package mailed by Mercer)
      * - or (if `includeFollowUp`) the last participation event is "package sent", or the `isPackageSent` participation event is the last but one, followed by a `latest` ("Latest package follow up") participation event.
     */
    hasOutstandingPkgOver6Months(includeFollowUp) { // ASK ---> I'm not sure about the naming and this belongs here or in Employment?
        if(!this.events?.last?.effDt) {
            console.warn('Participation hasOutstandingPkgOver6Months events.last.effDt is undefined, events:', this.events);
            return false;
        }
        const today = moment().format('YYYY-MM-DD');
        const isPackageSent = includeFollowUp ? (this.isPackageSent() || this.isPackageSentAndLatestFollowUp()) : this.isPackageSent();
        const packageSentEvent = this.isPackageSent() ? this.eventStatuses.last : this.isPackageSentAndLatestFollowUp() ? this.events.all[this.events.all.length - 2] : undefined;
        const outstandingEndDate = packageSentEvent ? moment(packageSentEvent.effDt).add(180, 'days').format('YYYY-MM-DD') : undefined;
        if (isPackageSent && outstandingEndDate &&  (outstandingEndDate < today)) return true;
    }

    /**
     * 
     * @returns true if the last event is `isPackageSent`: `a60oso` (Age 60: OSO - Outstanding Age60 Options. Package mailed by Mercer', 'Age60 Options. Package mailed by Mercer),
     * `l60oso` (LTD 60: OSO - Outstanding LTD60 Options. Package mailed by Mercer), `penOpo` (Retirement: OPO - Outstanding Pension Options. Options mailed by Mercer),
     * `trmPre` (Termination: OTR - Options package mailed by Mercer: entitled to refund), `trmPde` (Termination: OTD - Options package mailed by Mercer: entitled to deferred),
     * `trmOtn` (Termination: OTDN - Options package mailed by Mercer: entitled to a deferred with no additional benefit),
     * `trmPda` (Termination: OTDA - Options package mailed by Mercer: entitled to a deferred and additional benefit),
     * `deaOdo` (Death: ODO - Outstanding Deceased Options. Package mailed by Mercer)
     */
    isPackageSent() {
        return this.events.last.config.isPackageSent;
    }

    /**
     * 
     * @returns {boolean} true if:
     * - the last but one event is `isPackageSent`: `a60oso` (Age 60: OSO - Outstanding Age60 Options. Package mailed by Mercer', 'Age60 Options. Package mailed by Mercer),
     * `l60oso` (LTD 60: OSO - Outstanding LTD60 Options. Package mailed by Mercer), `penOpo` (Retirement: OPO - Outstanding Pension Options. Options mailed by Mercer),
     * `trmPre` (Termination: OTR - Options package mailed by Mercer: entitled to refund), `trmPde` (Termination: OTD - Options package mailed by Mercer: entitled to deferred),
     * `trmOtn` (Termination: OTDN - Options package mailed by Mercer: entitled to a deferred with no additional benefit),
     * `trmPda` (Termination: OTDA - Options package mailed by Mercer: entitled to a deferred and additional benefit),
     * `deaOdo` (Death: ODO - Outstanding Deceased Options. Package mailed by Mercer)
     * - and the last event is `latest` (Latest package follow up)
     */
    isPackageSentAndLatestFollowUp() {
        if (!this.events?.all?.length || this.events.all.length < 2){
            return false;
        }
        const lastButOneEvent = this.events.all[this.events.all.length - 2];
        return Boolean(lastButOneEvent?.config?.isPackageSent) && Boolean(this.events.last?.config?.isFollowUp);
    }

    /**
     * Eligibility end date: 1 year after the last eligibility event date
     * @returns if the participation has eligibilities (events with code `metEligDate` (ELIG - Meets eligibility on this date)),
     * return the last eligibility event date + 1 year . Otherwise return `undefined`.
     */
    eligibilityEndDate(){
        const eligEvent = this.eligibilities.last;
        if (eligEvent) return moment(eligEvent?.ets).add(1, 'year');
    }

    /**
     * Participation has a `isNOP` event: `a60Nop` (Age60: NOP - Active60 Declaration received: continue accruing) or `a60neg` (Age60: NOP - Member not entitled to Pension and work at age 60. Data confirmed by employer.) or `l60Nop` (LTD60: NOP - LTD60 Chose to continue LTD)
     * @returns 
     */
    hasNOP() { 
        return this.events.find(e => e.config.isNOP);
    }

    /**
     * Participation is pending but not close
     * @returns true if:
     * - participation has a pending event: `penDea` (Pending - Deceased) or `penTrm` (Pending - Terminated) or `penPnr` (Pending - Pensioner)
     * - and participation status is not close: not `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
     */
    isPendingClose() { return this.events.find(e => e.config.isPendingEvent ) && !this.status.isClose() }
    /**
     * Participation is pending or close
     * @returns true if:
     * - participation has a pending event: `penDea` (Pending - Deceased) or `penTrm` (Pending - Terminated) or `penPnr` (Pending - Pensioner)
     * - or participation status is close: `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled)
     */
    isPendingOrClosed() { return this.isPendingClose() || this.status.isClose() }

    /**
     * Participation is pending pensioner
     * @returns true if the last participation event with status is `penPnr` (Pending - Pensioner)
     */
    isPendingPensioner() { return this.events.getFiltered(e => e.config.status || e.config.includeInDesc).last.code === 'penPnr' }

    getActiveStatusEvent() { return this.eventStatuses.find(eve => eve.config.isEnrollEvent) }

    /**
     * Whitelist of the Participation {@link definitions} properties to include in the payload
     * @returns {['events', 'no']}
     */
    getPayloadInclusions() { return ['events', 'no']};

    getFlowStatusDesc(event, isFormal = false) {
        let flow = ParticipationTaskConfig[event.code].desc;
        let tasks = event.subEvent.constructor.ref.getTaskList(this, event);
        let desc = 'Complete';
        let completeTasks = tasks.filter((value) => value.validated);
        let complete = completeTasks.length;
        let lastCompletedTask = completeTasks[complete-1];
        let all = tasks.length;

        let task = tasks.find((value) => !value.validated);
        if (task) desc = task.config.task;
        if (isFormal) desc = complete !== 0 ? (lastCompletedTask?.config.formalDescription || SubEventConfig.default.formalDescription) : SubEventConfig.default.initialFormalDescription;

        return isFormal ? desc : `${complete}/${all} ${flow} - ${desc}`;
    }

    addEvent(event, params = {}) {
        this.events.pushEvent(event, {participation: this, ...params});
    }
    replaceEvent(oldEvent, newEvent, params = {}) {
        this.events.replaceEvent(oldEvent, newEvent, {participation: this, ...params});
    }
    updateEvent(oldEvent, newEvent, params = {}) {
        this.events.updateEvent(oldEvent, newEvent, {participation: this, ...params});
    }
    deleteEvent(event, params = {}) {
        this.events.deleteEvent(event, {participation: this, ...params});
    }

    /**
     * Get the eligibility by year and employer
     * @param {number} year - The year
     * @param {Employer} employer - The employer
     * @returns {ParticipationEvent | undefined} The eligibility event or undefined if not found
     */
    getEligbilityByYearAndEmployer(year, employer) {
        const alreadyEligibleEvents = this.events.getFiltered(x=>x.config.isAlreadyEligible);
        const eligibleEvents = this.eligibilities;

        return [...alreadyEligibleEvents.all, ...eligibleEvents.all].find(e => 
            e.effMoment.year() === year && 
            e.pointers.find(p=>p.name === 'employer' && p.instanceKey === employer.keyValue)
        );
    }

    /**
     * Check if the employment is the last (by event status date)
     * 
     * Same rule as in `/src/views/member/reports/EventReport.jsx`
     * @param {Employment} employment 
     * @returns 
     */
    isLastEmployment(employment){
        const lastEmploymentDate = Math.max(...this.employments?.map(otherEmployment => otherEmployment.events?.statusEvents?.last.ets))
        const isLastEmployment = this.employments?.find(otherEmployment => otherEmployment.keyValue === employment.keyValue)?.events?.statusEvents?.last.ets === lastEmploymentDate;
        return isLastEmployment;
    }

    static key = ['membership', 'no']
    // see schema in src/model/membership/Participation.js in Services
    static definitions = {
        membership: { key: true, transient: true, ref: require('../Membership') },

        /** 
         * Participation number. Used for updating data.
         * There might be gaps in the pp numbers of all the participations of a member (in the case when a participation is deleted, they are not reordered)
         */
        no: { type: STRING, text: 'Participation #' },
        /** 
         * Corrected participation number (in the case when a participation is deleted and there are gaps in the participations numbers "no", this value has the correct order without gaps).
         * Use this value for displaying the pp number in the app and in reports. Do not use it for updating data.
         */
        reorderedPpNo:  { transient: true, type: STRING, text: 'Participation #' },
        mercerKey: { type: STRING, text: 'Mercer Key' },
        
        beneficiaries: { transient: true, ref: require('../../pension/member/Beneficiaries'), text: 'Beneficiaries' },
        employments: { transient: true, map: true, ref: require('../../employment/Employment') },
        event: {transient: true, ref: ParticipationEvent, text: 'Status'},
        messages: { transient: true, ref: ParticipationMessages, text: 'Message'},
        tasks: { transient: true, ref: ParticipationTasks, text: 'Tickets'},
        employers: { transient: true, type: RefList, text: 'Related employers'},

        closeDate: { abstract: true, type: DATE, text: 'Close Date' },
        joinDt: { abstract: true, type: DATE, text: 'Join Date' },
        eligibilities: {abstract: true, type: DATE, text: 'Eligibility Dates'},
        firstEligibility: {abstract: true, type: DATE, text: 'First Eligibility Date'},
        firstEligibilityDesc: {abstract: true, type: STRING, text: 'First Eligibility Date'},
        lastEligibility: {abstract: true, type: DATE, text: 'Last Eligibility Date'},
        isMER: {abstract: true, type: BOOLEAN, text: 'Working at Multiple Employers'},
        person: { abstract: true, ref: require('../../person/Person'), text: 'Person'},
        statusDesc: { abstract: true, text: 'Status'},
        lastStatusEvent: { abstract: true, ref: ParticipationEvent, text: 'Last Event Status'},
        status: { abstract: true, ref: ParticipationStatus, text: 'Status'},
        message: { abstract: true, ref: ParticipationMessage, text: 'Message'},
        /**
         * Participation number label. Can be a number (like "3") or a letter (like "P").
         * The value will be "C" is the last status event is Cancelled, "NE" if the last status event is Ineligible, 
         * or "P" if the last status event is Potential, 
         * otherwise it will be the `reorderedPpNo` value if it exists, or the `no` value.
         */
        ppNo: {abstract: true, type: STRING, text: 'Participation #'},
        nativeStatus: { abstract: true, type: STRING, text: 'Status' },
        cqppStatus: { abstract: true, type: STRING, text: 'Status' },
        taxStatus: { abstract: true, type: STRING, text: 'Status' },
        
        events: { isListRef: true, ref: ParticipationEvents, text: 'Message'},
        payout: {ref: Payout, text: 'Payout'},
        pension: { ref: ParticipationPension, text: 'Pension'},
    }

    
}
