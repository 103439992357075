import { useState, useEffect } from 'react';
import { Col, Row  } from '../../framework/containers';
import { Button, UploadButton, Select, Icon } from '../../framework/controls';
import { PageTitle } from '../../framework/components';
import IMPORT_TYPES from '../../business/ImportBusiness.js/ImportRegistry';
import Loader from '../../components/containers/Loader';
import { Alert } from '../../framework/controls';
import CardContainer from '../../components/containers/CardContainer';
import { EInput } from '../../framework/components';
import ImportOptions from '../../entities/import/ImportOptions';
import { Excel } from '../../framework/utils';
import ExportReport from '../../entities/import/ImportExportReport';
import CustomDropdown from '../../components/form/Dropdown';
import ImportRowDetail from '../../entities/import/ImportRowDetail';
import ImportMessage from '../../entities/import/ImportMessage';

const ImportPage = () => {

	const [uploadFile, setUploadFile] = useState(null);
	const [results, setResults] = useState(null);
	const [loaderProgress, setLoaderProgress] = useState(null);
	const [selectedImportType, setSelectedImportType] = useState(Object.values(IMPORT_TYPES)[0].type);
	const [isPreview, setIsPreview] = useState(false);
	const [sortType, setSortType] = useState('relevance');

	const sortOptions = [
		{ value: 'relevance', text: 'Sort by Relevance' },
		{ value: 'rowId', text: 'Sort by Row ID' },
	];

	const detailsHaveErrors = results?.details?.some(detail => detail.hasErrors);

	if (detailsHaveErrors) {
		sortOptions.push({ value: 'errors', text: 'Errors Only' });
	}

	const getByType = (type) => {
		return Object.values(IMPORT_TYPES).find(x => x.type === type);
	}

	const importType = getByType(selectedImportType);
	const messageFactory = importType.messageFactory();

	/**
	 * Creates new cards where each card has a unique error message, a count, and a sublist of which rows have the error
	 * @param {Array} details - The details to create a unique error view from
	 * @returns {Array[ImportRowDetail]} - The unique error details
	 */
	const createUniqueErrorDetailView = (details) => {
		const uniqueErrors = { };

		details.forEach(detail => {
			const detailErrors = detail.messages.filter(m => m.isError());
			if (detailErrors.length > 0) {
				for (const error of detailErrors) {
					const uniqueError = uniqueErrors[error.key] || {
						message: error.key,
						count: 0,
						sublist: []
					};
					uniqueError.count++;
					uniqueError.sublist.push({
						message: error.message,
						title: detail.title
					});
					uniqueErrors[error.key] = uniqueError;
				}
			}
		});

		//convert to Array of ImportRowDetail
		return Object.values(uniqueErrors).map((x, index) => {
			const template = messageFactory.getTemplateByKey(x.message);
			const detail = new ImportRowDetail(index, `${template.messageTemplate} (${x.count})`);
			const uniqueMessages = {}
			for (const error of x.sublist) {
				const uniqueMessage = uniqueMessages[error.message] || []
				uniqueMessage.push(error.title);
				uniqueMessages[error.message] = uniqueMessage;
			}

			// iterate over object to add messages to importRowDetail, order by count of titles
			for (const [message, titles] of Object.entries(uniqueMessages).sort((a, b) => b[1].length - a[1].length)) {
				const importMessage = messageFactory.createUnregistered(`${message} (${titles.length})`, ImportMessage.TYPE.ERROR, titles);
				detail.addMessage(importMessage);
			}

			return detail;
		});
	}

	const getSortedDetails = (details) => {
		if (!details) return [];
		
		switch (sortType) {
			case 'rowId':
				return [...details].sort((a, b) => a.rowId - b.rowId);
			case 'errors':
				return createUniqueErrorDetailView(details);
			case 'relevance':
			default:
				return [...details].sort((a, b) => {
					// First compare by severity
					if (a.severityPonderation !== b.severityPonderation) {
						return b.severityPonderation - a.severityPonderation;
					}
					// Then by number of focused messages
					const aFocusCount = a.messages.filter(m => m.focus).length;
					const bFocusCount = b.messages.filter(m => m.focus).length;
					return bFocusCount - aFocusCount;
				});
		}
	};
	
	const handleDownloadImportTemplate = () => {
		getByType(selectedImportType).template();
	}

	/**
	 * Import a file, mock import to see warnings and potential errors
	 * @param {*} file 
	 */
	const handleImport = async (file) => {
		setUploadFile(file);
		const results = await getByType(selectedImportType).importFunction(file, 
			new ImportOptions({
				commit: false, 
				loaderController: setLoaderProgress 
			})
		);

		setResults(results);
		setIsPreview(true);
	}

	/**
	 * Save the import, commit the import to the database
	 */
	const handleSave = async () => {
		const results = await getByType(selectedImportType).importFunction(uploadFile, 
			{ commit: true, loaderController: setLoaderProgress });
		setResults(results);
		setIsPreview(false);
	}

	/**
	 * Reset the import state
	 */
	const handleReset = () => {
		setUploadFile(null);
		setResults(null);
		setIsPreview(false);
	}

	const handleExportDetails = () => {
		const data = ExportReport.getData(results.details.sort(byRelevance));
		const HEADER_COLS = ['rowId', 'severity', 'message'];
		const excelHeaders = new Excel.Headers(ExportReport, HEADER_COLS);
		const excel = new Excel('ImportSummary');
		excel.addSheet(excelHeaders.list, data);
		excel.download();
	}
	
	const byRelevance = (a, b) => {
		return a.severityPonderation < b.severityPonderation ? 1 : -1;
	};

	useEffect(() => {
		setLoaderProgress(null);

		if (detailsHaveErrors) {
			setSortType('errors');
		} else {
			setSortType('relevance');
		}

	}, [results]);

	useEffect(() => {
		handleReset();
	}, [selectedImportType]);

	const resultsHaveErrors = results?.errors?.length > 0; 

	// Create options for dropdown
	const importTypeOptions = Object.values(IMPORT_TYPES).map(({ type, label }) => ({
		key: type,
		text: label
	}));

	return <>
		<Row className='align-content-between' >
			<PageTitle className='mt-1'>Import</PageTitle>
		</Row>
		<div className="h-90 content-container members-container">
			{ resultsHaveErrors && 
				<div className='mb-3'>
					<Alert variant="danger"> { results?.errors?.map(x=> x).join('\n') } </Alert>
				</div>
			}
			{ results && !resultsHaveErrors && !isPreview && 
				<div className='mb-3'>
					<Alert variant="success"> Changes saved successfully </Alert>
				</div>
			}
			<div className="mb-3">
				<div className="bold-label">Import Settings
				<Icon tooltip={'Open Documentation'} icon='question-circle' className='pl-2 clickable' tooltip-right={true} 
						onClick={() => window.open(getByType(selectedImportType).documentation, '_blank')}
					/>

				</div>
				<div className='line line-bottom g15'>
					<EInput name='importType' instance={selectedImportType}>
						<Select 
							options={importTypeOptions}
							value={selectedImportType}
							onChange={setSelectedImportType}
							disabled={!!uploadFile}
						/>
					</EInput>
					<Button 
						className='btn-secondary bottom-offset' 
						onClick={() => handleDownloadImportTemplate(selectedImportType)}
					>
						Download Template
					</Button>
					
					{!uploadFile ? (
						<UploadButton 
							id={`upload-${selectedImportType}`}
							onUpload={(upload) => handleImport(upload, selectedImportType)}
							text={getByType(selectedImportType).label}
							accept='.xls,.xlsx,.csv'
							displayfileName={false}
							style={{ marginBottom: '3px' }}
						/>
					) : (
						<>
							<CustomDropdown 
								buttonText="Sort"
								buttonVariant="secondary"
								options={sortOptions}
								cn="bottom-offset no-mar"
								onSelect={setSortType}
								activeValue={sortType}
								showCheck={true}
							/>
							<Button className='btn-primary bottom-offset' onClick={handleReset}>Reset</Button>
							<Button className='btn-primary bottom-offset' onClick={handleExportDetails} disabled={resultsHaveErrors}>Export</Button>
							{ isPreview && 
								<Button 
									className='btn-primary bottom-offset' 
									onClick={handleSave} 
									disabled={detailsHaveErrors || resultsHaveErrors}
								>
									{detailsHaveErrors ? 'File has errors' : 'Save'}
								</Button> 
							}
	
						</>
					)}
				</div>
			</div>
			{ loaderProgress && <div className='loader-container mt-3'>
				<Loader processed={loaderProgress.processed} total={loaderProgress.total}/> 
			</div> }
			<div className='mt-3'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='bold-label'>{
						isPreview ? 
							sortType === 'errors' ? 
								'You have errors that need to be fixed before saving' 
								: 
								'Preview Results' 
							: 
							'Import Results'
						}
					</div>
				</div>
				<CardContainer 
					details={getSortedDetails(results?.details)} 
					defaultText="No details. Please upload a file to view expected changes." 
				/> 
			</div>
		</div>
	</> 
}


export default ImportPage;



