import { EmploymentEvent } from "../../../entities/employment/EmploymentEvent";
import { employmentEventConfigs } from "../../../entities/employment/EmploymentConfig";
import { 
    phraseToPascal, 
    isValidOption, 
    isValidYesNo, 
    enumToOptions, 
    isValueUndefined, 
    isValidSIN, 
    isValidMoment, 
    cleanseSIN 
} from "../../../framework/utils/helper";
import ImportFieldDefinition from "../../../entities/import/ImportFieldDefinition";
import ImportFieldValidationResponse from "../../../entities/import/ImportFieldValidationResponse";
import { Person, Employment } from "../../../entities";
import WorkSchedule from "../../../entities/employment/EmploymentWorkSchedule";
import { toUpperCase, excelToMoment, toOptionValue, toYesNo, toBlankIfUndefined, toEnumValue } from "../../../framework/utils/formating";

export default [
    new ImportFieldDefinition('employerCode', 'ER Code', {
        aliases: ['code'],
        format: toUpperCase,
        validation: (value) =>  new ImportFieldValidationResponse(!isValueUndefined(value), 'MISSING_EMPLOYER_CODE'),
        required: true
    }),
    new ImportFieldDefinition('sin', 'SIN', {
        format: (value) => cleanseSIN(value),
        validation: (value) => {
            if (isValueUndefined(value)) {
                return new ImportFieldValidationResponse(false, 'MISSING_SIN');
            }
            return new ImportFieldValidationResponse(isValidSIN(value), 'INVALID_VALUE', { field: 'SIN', value: value });
        },
        required: true
    }),
    new ImportFieldDefinition('hiredDate', 'Hired Date', {
        aliases: ['hiredate', 'hiredateddmmmyy'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Hired Date'),
    }),
    new ImportFieldDefinition('hiredDateComment', 'Hired Date Comment', {
        aliases: ['hiredatecomment'],
    }),
    new ImportFieldDefinition('payrollStartDate', 'Payroll Start Date', {
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Payroll Start Date'),
    }),
    new ImportFieldDefinition('payrollStartDateComment', 'Payroll Start Date Comment'),
    new ImportFieldDefinition('firstName', 'First Name', {
        format: phraseToPascal,
    }),
    new ImportFieldDefinition('lastName', 'Last Name', {
        format: phraseToPascal,
    }),
    new ImportFieldDefinition('gender', 'Sex (male/female)', {
        aliases: ['gender', 'sex'],
        format: (value) => toOptionValue(value, Person.definitions.gender.options),
        validation: (value) => nullOrValidOption(value, Person.definitions.gender.options, 'Sex'),
    }),
    new ImportFieldDefinition('lng', 'Language (en/fr)', {
        aliases: ['lng', 'language'],
        format: (value) => toOptionValue(value, Person.definitions.lng.options),
        validation: (value) => nullOrValidOption(value, Person.definitions.lng.options, 'Language'),
    }),
    new ImportFieldDefinition('dob', 'Date of Birth', {
        aliases: ['dobddmmmyy'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Date of Birth'),
    }),
    new ImportFieldDefinition('isN', 'Native (y/n)', {  
        aliases: ['native'],
        format: toYesNo,
        validation: (value) => nullOrValidYesNo(value, 'Native'),
    }), 
    new ImportFieldDefinition('isNDate', 'Native Date', {
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Native Date'),
    }),
    new ImportFieldDefinition('isTP', 'Tax Payer (y/n)', {
        aliases: ['taxpayer', 'paystax'],
        format: toYesNo,
        validation: (value) => nullOrValidYesNo(value, 'Tax Payer'),
    }),
    new ImportFieldDefinition('isTPDate', 'Tax Payer Date', {
        aliases: ['paystaxdate'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Tax Payer Date'),
    }),
    new ImportFieldDefinition('noEmp', 'Employee #', {
        aliases: ['noemp', 'noemployee', 'employeeno', 'employeenumber'],
        format: toBlankIfUndefined,
    }),
    //is CQ
    new ImportFieldDefinition('isCQ', 'CPP/QPP (y/n)', {
        aliases: ['iscq', 'payscppqpp', 'cppqpp'],
        format: toYesNo,
        validation: (value) => nullOrValidYesNo(value, 'CPP/QPP'),
    }),
    new ImportFieldDefinition('isCQDate', 'CPP/QPP Date', {
        aliases: ['iscqdate', 'payscppqppdate'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'CPP/QPP Date'),
    }),
    // weekly schedule
    new ImportFieldDefinition('workSch', 'Work Schedule (Ex: 32.5, 35, 40…)', {
        aliases: ['worksch', 'workschedule'],
        format: (val) => toEnumValue(val, WorkSchedule), 
        validation: (value) => nullOrValidOption(value, enumToOptions(WorkSchedule), 'Work Schedule'),
    }),
    new ImportFieldDefinition('workSchDate', 'Work Schedule Date', {
        aliases: ['workschdate'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Work Schedule Date'),
    }),
    new ImportFieldDefinition('employmentType', 'Employment Type (pt/ft/cs)', {
        aliases: ['employmenttype', 'employmenttypeptstcs'],
        format: (val) => toOptionValue(val, Employment.definitions.employmentType.options),
        validation: (value) => nullOrValidOption(value, Employment.definitions.employmentType.options, 'Employment Type'),
    }),
    new ImportFieldDefinition('employmentTypeDate', 'Employment Type Date', {
        aliases: ['emptypedate'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Employment Type Date'),
    }),
    new ImportFieldDefinition('eligibilityDt', 'Eligibility Date', {
        aliases: ['meteligibility'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Eligibility Date'),
    }),
    new ImportFieldDefinition('eligibilityDtComment', 'Eligibility Date Comment', {
        aliases: ['meteligibilitycomment'],
    }),
    new ImportFieldDefinition('joinDt', 'Join Date', {
        aliases: ['joindate'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Join Date'),
    }),
    new ImportFieldDefinition('joinDtComment', 'Join Date Comment', {
        aliases: ['joindatecomment'],
    }),
    new ImportFieldDefinition('nonEligibilityDt', 'Not Eligible Date', {
        aliases: ['noneligibility', 'noneligibilitydate'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Not Eligible Date'),
    }),
    new ImportFieldDefinition('nonEligibilityDtComment', 'Not Eligible Date Comment', {
        aliases: ['noneligibilitycomment'],
    }),
    new ImportFieldDefinition('eventStatus', 'Status', {
        aliases: ['status', 'status31dec'],
        format: (val) => toEmploymentEventCode(val),
    }),
    new ImportFieldDefinition('eventStatusDate', 'Status Date', {
        aliases: ['statusdate'],
        format: excelToMoment,
        validation: (value) => nullOrValidMoment(value, 'Status Date'),
    }),
    new ImportFieldDefinition('eventStatusDateComment', 'Status Comment', {
        aliases: ['statuscomment', 'statusdatecomment'],
    }),
];

// helper functions

/**
 * Validate if the value is null or a valid moment
 * @param {string} value - The value to validate
 * @param {string} label - The label of the field
 * @returns {ImportFieldValidationResponse} The validation response
 */
function nullOrValidMoment(value, label) {
    return new ImportFieldValidationResponse(isValueUndefined(value) || isValidMoment(value), 'INVALID_VALUE', { field: label, value: value });
}

/**
 * Validate if the value is null or a valid yes/no
 * @param {string} value - The value to validate
 * @param {string} label - The label of the field
 * @returns {ImportFieldValidationResponse} The validation response
 */
function nullOrValidYesNo(value, label) {
    return new ImportFieldValidationResponse(isValueUndefined(value) || isValidYesNo(value), 'INVALID_VALUE', { field: label, value: value });
}

/**
 * Validate if the value is null or a valid option
 * @param {string} value - The value to validate
 * @param {string} options - The options to validate against
 * @param {string} label - The label of the field
 * @returns {ImportFieldValidationResponse} The validation response
 */
function nullOrValidOption(value, options, label) {
    return new ImportFieldValidationResponse(isValueUndefined(value) || isValidOption(value, options), 'INVALID_VALUE', { field: label, value: value });
}

function toEmploymentEventCode(value) {
    let code = EmploymentEvent.getEventCodeByDescription(value);
    if (code && employmentEventConfigs[code]?.useInMembersUploadStatusUpdate) {
        return code;
    }
}