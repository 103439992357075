import CalendarValidation from "../../../framework/components/taskValidation/CalendarValidation";
import { moment } from "../../../framework/utils/helper";


export const LtdInsuranceTaskResolution = ({instance, onClose, onSave}) => {
    const ltdEvent = instance.eventStatuses.find(ev => ev.status.isLtd());
    const nextStatus = instance.eventStatuses.getNext(ltdEvent);
    const insuranceLetterEvents = instance.events.getFiltered(ev => ev.config.insuranceLetterSent);
    var taskDate = '';
    var relatedEvent = '';
    // If their is no insurance letter event or the insurance letter event is after the next status, the task date is the LTD event date
    if(insuranceLetterEvents.isEmpty() || moment(insuranceLetterEvents.first.ets).isSameOrAfter(nextStatus?.ets)){
        taskDate = moment(ltdEvent.ets);
        relatedEvent = '[Related status: LTD]';
    } else {
        taskDate = moment(nextStatus.ets);
        relatedEvent = '[Related status: ' + nextStatus.desc + ']';
    }


    return <CalendarValidation 
            startDate={taskDate} 
            instance={instance}
            message='Has insurance letter been submitted ?'
            taskSave={(employment,date, cmt) => {employment.addEvent({code: 'ltdIns', cmt: cmt, ets: moment(date).valueOf()})}} 
            onClose={onClose}
            onSave={onSave}
            initialComment={relatedEvent}
        />
}