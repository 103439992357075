/**
 * ImportResponse is a class that represents the response from an import operation
 * @param {Array<any>} results - The results of the import operation
 * @param {Array<string>} errors - The errors that occurred during the import operation
 */

export default class ImportResponse {
    constructor(details, errors = []) {
        this.details = details;
        this.errors = errors;
    }

    get hasErrors() {
        return this.errors.length > 0;
    }
}