import ImportMessage from './ImportMessage';

/**
 * Tracks messages and status for a specific import row
 */
class ImportRowDetail {
    /**
     * @param {string} rowId - Unique identifier for the row
     * @param {string} title - Title or description for this row
     */
    constructor(rowId, title) {
        // check if rowId is a number
        if (isNaN(rowId)) {
            throw new Error('RowId must be a number');
        }

        this.rowId = rowId;
        this.title = title;
        this.messages = [];
        this.redirect = null; // use in case of redirect to another page
    }

    /**
     * @returns {boolean} true if there are any messages
     */
    get hasMessages() {
        return this.messages.length > 0;
    }

    /**
     * @returns {boolean} true if there are any error messages
     */
    get hasErrors() {
        return this.messages.some(msg => msg.isError());
    }

    /**
     * @returns {boolean} true if there are any danger messages
     */
    get hasDangers() {
        return this.messages.some(msg => msg.isDanger());
    }


    /**
     * @returns {boolean} true if there are any warning messages
     */
    get hasWarnings() {
        return this.messages.some(msg => msg.isWarning());
    }

    /**
     * Add a message
     * @param {ImportMessage} message - The message to add
     * @throws {Error} If message is not an instance of ImportMessage
     * @returns {ImportRowDetail} this to allow chaining
     */
    addMessage(message) {
        if (!(message instanceof ImportMessage)) {
            throw new Error('Message must be an instance of ImportMessage');
        }
        this.messages.push(message);

        return this;
    }

    /**
     * Clear all messages
     */
    clearMessages() {
        this.messages = [];
    }

    /**
     * Get the string representation of the row messages and title 
     * @returns {string} - The string representation of the row detail
     */
    toString () {
        const formatMessages = (messages, indent = '') => {
            return messages.map(msg => {
                let result = `${indent}• ${msg.message}`;
                if (msg.subList) {
                    const subListIndent = indent + '          ';
                    const formattedSublist = msg.subList.map(subMsg => 
                        `${subListIndent}• ${subMsg}`
                    ).join('\n');
                    result += '\n' + formattedSublist;
                }
                return result;
            }).join('\n');
        };

        return `${this.title}\n${formatMessages(this.messages, '  ')}`;
    }

    /**
     * @param {string} key - The key of the message to check
     * @returns {boolean} true if the message with the given key is an error
     */
    hasError(key) {
        return this.messages.some(msg => msg.key === key && msg.isError());
    }

    get severityLabel() {
        switch (this.severity) {
            case 'e': return 'Error';
            case 'd': return 'Danger';
            case 'w': return 'Warning';
            case 'i':
            default: return 'Info';
        }
    }

    /**
     * Get the highest severity level from all messages
     * @returns {'e' | 'w' | 't' | 'i'} severity codes
     */
    get severity() {

        if (this.hasErrors) {
            return 'e';
        }

        if (this.hasDangers) {
            return 'd';
        }
        
        if (this.hasWarnings) {
            return 'w';
        }

        if (this.hasMessages) {
            return 'i';
        }

        return 't';
    }

    /**
     * Get the severity ponderation
     * @returns {number} - The severity ponderation
     */
    get severityPonderation() {
        switch (this.severity) {
			case 'e': return 4;
			case 'd': return 3;
			case 'w': return 2;
			case 't':
			case 'i':
			default: return 1;
		}
    }
}

export default ImportRowDetail;
