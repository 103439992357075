import { useState } from "react";
import { Dropdown } from "react-bootstrap";

const CustomDropdown = ({ buttonText, buttonVariant, options, onSelect, cn, activeValue, showCheck }) => {
    const [active, setActive] = useState();

    const handleSelect = (value) => {
        setActive(value);
        onSelect(value);
    }

    return (
        <Dropdown onSelect={handleSelect} className={cn}>
            <Dropdown.Toggle
                variant={buttonVariant ?? "primary"}
                id="dropdown-basic"
                className={cn}
            >
                {buttonText}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {options.map((option) => (
                    <Dropdown.Item 
                        eventKey={option.value} 
                        active={showCheck ? option.value === activeValue : option.value === active} 
                        key={option.value}
                    >
                        {option.text}
                        {showCheck && option.value === activeValue && <span className="pl-2">✓</span>}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CustomDropdown;
