/**
 * Generic class to handle import messages
 */
class ImportMessage {
    static TYPE = {
        ERROR: 'e',
        INFO: 'i',
        WARNING: 'w',
        DANGER: 'd'
    };

    /**
     * Constructor
     * @param {string} key - Message key
     * @param {string} message - Message text
     * @param {string} type - Message type
     * @param {Object} subList - Optional sublist of data
     * @param {Object} focus - Optional focus object to highlight in the UI
     */
    constructor(key, message, type, subList = null, focus) {
        this.key = key;
        this.message = message;
        this.type = type;
        this.subList = subList;
        this.focus = focus;
    }

    isError() {
        return this.type === ImportMessage.TYPE.ERROR;
    }

    isInfo() {
        return this.type === ImportMessage.TYPE.INFO;
    }

    isWarning() {
        return this.type === ImportMessage.TYPE.WARNING;
    }

    isDanger() {
        return this.type === ImportMessage.TYPE.DANGER;
    }
}

export default ImportMessage;
