import { isValueUndefined } from "../../framework/utils/helper";

class ImportChangeLogger {
    constructor() {
        this.changes = [];
    }

    logChange(property, oldValue, newValue) {
        this.changes.push({property, oldValue, newValue});
    }

    getChanges() {
        return this.changes;
    }

    toString() {
        return this.toStringList().join(', ');
    }

    toStringList() {
        return this.changes.map(change => `${change.property}: ${isValueUndefined(change.oldValue) ? 'N/A' : change.oldValue} → ${change.newValue}`);
    }

    total() {
        return this.changes.length;
    }

    merge(other) {
        this.changes = this.changes.concat(other.changes);
        return this;
    }
}

export default ImportChangeLogger;