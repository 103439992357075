import Amplify from "aws-amplify";

const amplifyCredentials = {
        Auth: {
          userPoolId: process.env.REACT_APP_USER_POOL_ID,
          userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
          region: process.env.REACT_APP_AWS_REGION,
        },
      };

class Auth {
  constructor() {
    Amplify.configure(amplifyCredentials);
    this.currentUserPromise = null; // Cache for the current user promise
  }

  signIn(userName, password) {
    return Amplify.Auth.signIn(userName, password)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.newPasswordRequiredForUser = user;
          throwError(
            errors.newPasswordRequired.code,
            errors.newPasswordRequired.message
          );
        }
        return user;
      })
      .catch((err) => {
        if (err.errorCode === "newPasswordRequired") {
          throw err;
        } else if (err.code === "UserNotConfirmedException") {
          throwError(
            errors.userNotConfirmed.code,
            errors.userNotConfirmed.message,
            err
          );
        } else if (err.code === "PasswordResetRequiredException") {
          throwError(
            errors.resetPasswordRequired.code,
            errors.resetPasswordRequired.message,
            err
          );
        } else {
          throwError(errors.signInError.code, errors.signInError.message, err);
        }
      });
  }

  signOut() {
    return Amplify.Auth.signOut();
  }

  getCurrentUser() {
    return Amplify.Auth.currentAuthenticatedUser().then((user) => {
      user.role = user.attributes["custom:role"];
      user.isSuperAdmin = user.attributes["custom:super"] === 'true';
      user.isOsedeaDev = user.attributes["email"].endsWith?.('@osedea.com');
      return user;
    });
  }

  getCurrentUser() {
    // If there's already a request in progress, return the cached promise
    // this avoids hundreds of refreshe token requests being fired off at once
    if (this.currentUserPromise) {
      return this.currentUserPromise;
    }

    this.currentUserPromise = Amplify.Auth.currentAuthenticatedUser()
      .then((user) => {
        user.role = user.attributes["custom:role"];
        user.isSuperAdmin = user.attributes["custom:super"] === 'true';
        user.isOsedeaDev = user.attributes["email"].endsWith?.('@osedea.com');
        this.currentUserPromise = null; // Clear the cache once the request is fulfilled
        return user;
      })
      .catch((err) => {
        this.currentUserPromise = null; // Ensure to clear the cache on error as well
        throw err;
      });

    return this.currentUserPromise;
  }

  getUserActions() {
    return this.getCurrentUser().then((user) => {
      return (
        (user.role && groups[user.role] && groups[user.role].actions) || []
      );
      // return (user.role && Role.roles[user.role] && Role.roles[user.role].actions) || []
    });
  }

  getCurrentUserResources() {
    return this.getCurrentUser().then((user) => {
      const resources = user.attributes["custom:resources"]
        ? user.attributes["custom:resources"].split(";")
        : [];
      return resources;
    });
  }
  getUserToken() {
    return this.getCurrentUser().then(
      (user) => user.signInUserSession.idToken.jwtToken
    );
  }

  isAdmin() {
    return this.getCurrentUser().then((user) => {
      return user.attributes["custom:resources"] === "*";
    });
  }

  isAuthenticated() {
    return this.getCurrentUser()
      .then((user) => true)
      .catch((err) => false);
  }

  setPassword(password) {
    if (this.newPasswordRequiredForUser) {
      return Amplify.Auth.completeNewPassword(
        this.newPasswordRequiredForUser,
        password
      )
        .then((user) => {
          this.newPasswordRequiredForUser = null;
        })
        .catch((err) => {
          if (err.code === "InvalidPasswordException") {
            throwError(errors.invalidPassword.code, err.message, err);
          } else {
            throwError(err.code, err.message, err);
          }
        });
    } else {
      return Promise.resolve({});
    }
  }

  changePasword(username, oldPassword, newPassword) {
    return Amplify.Auth.changePassword(
      username,
      oldPassword,
      newPassword
    ).catch((err) => {
      throwError(errors.passwordChangeError.code, err.message, err);
    });
  }

  resetPassword(username, code, newPassword) {
    return Amplify.Auth.forgotPasswordSubmit(username, code, newPassword).catch(
      (err) => {
        throwError(errors.passwordChangeError.code, err.message, err);
      }
    );
  }

  requestCode(username) {
    return Amplify.Auth.forgotPassword(username).catch((err) => {
      if (err.code === "UserNotFoundException") {
        throwError(errors.userNotFound.code, errors.userNotFound.message, err);
      }
    });
  }
}

const errors = {
  userNotConfirmed: {
    code: "userNotConfirmed",
    message: "User not confirmed",
  },
  newPasswordRequired: {
    code: "newPasswordRequired",
    message: "New Password Required",
  },
  resetPasswordRequired: {
    code: "resetPasswordRequired",
    message: "Reset Password Required",
  },
  passwordChangeError: {
    code: "passwordChangeError",
    message: "Error changing Password",
  },
  signInError: {
    code: "signInError",
    message: "Invalid Username or Password",
  },
  userNotFound: {
    code: "userNotFound",
    message: "Username not found",
  },
  invalidPassword: {
    code: "invalidPassword",
    message: "Invalid Password",
  },
};
const groups = {
  EMPLOYER: {
    actions: ["viewEmployees", "viewRemittances"],
  },
  MULTI_EMPLOYER: {
    actions: ["viewEmployees", "viewRemittances", "viewEmployers"],
  },
  ADMIN: {
    actions: ["*"],
  },
};
function throwError(errorCode, text, err) {
  const error = err || new Error(text);
  error.errorCode = errorCode;
  error.text = text;
  throw error;
}

const instance = new Auth();
export default instance;