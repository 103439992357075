import React from "react"
import { renderAmount, renderComponent } from '../../../framework/utils/renders'
import { Icon, Table } from '../../../framework/controls'
import { downloadPdf } from '../../../framework/utils/pdf' 

import Remittance from '../../../entities/pension/Remittance'
import { AdjustmentService, ConfigService, RemittanceService } from '../../../services'
import RemittanceStatus from "../../../entities/pension/RemittanceStatus"
import InvoiceOrStatementPdf from "../reporting/InvoiceOrStatementPDF"
import useNotification from "../../../hooks/useNotification"
import useUser from "../../../hooks/useUser"
import { isEmptyObject } from "jquery"

const RemittanceBalance = ({ 
    employer, 
    onSelect,
    remittances, 
    variant,
    enableDownloadCsv,
    enableDownloadJson,
    exportFileName
}) => {
    const user = useUser();
    const { addMessage } = useNotification();
    const actionIcon = (value, instance) => renderComponent(
        <Icon 
            icon={instance && instance.shouldRecalculate ? 'exclamation-triangle' : (instance.validated ? 'file-invoice' : 'file-alt')} 
            large
            tooltip={instance && instance.shouldRecalculate 
                ? "Please re-save the remittance to update the Credit Used due to adjustment changes." 
                : (instance.validated ? "Download Statement" : "Download Summary")
            } 
            tooltip-left 
            className={instance && instance.shouldRecalculate ? "text-danger" : "" }
        />
    );

    const remittanceStateIcon = (value, instance) => {
        let tooltip = 'Open remittance';
        let icon = 'times'
        if (!instance.validated) {
            tooltip = 'Open remittance'
            if (instance.shouldRecalculate) { 
                tooltip = 'Please re-save the remittance to update the Credit Used due to adjustment changes.'
                icon = 'exclamation-triangle';
            }
            if (instance.status.isNew()) {
                tooltip = 'New remittance. Please ensure contributions have been calculated at least once before validating.'
                icon = 'exclamation-triangle';
            }
        } else {
            if (user.isSuperAdmin && !instance.isOpen()) {
                icon = 'unlock-alt';
                tooltip = 'Unlock closed month as a Super Admin';
            } else {
                icon = 'calendar-check'
                tooltip = 'A closed remittance cannot be re-opened';
            }
        }
        return renderComponent(
            <Icon 
                icon={icon} 
                large
                tooltip={tooltip} 
                tooltip-left 
                className={getColor(instance)}
            />
        )}

    const getColor = (instance) => {
        let color = 'text-danger';
        if (instance?.isOpen() && instance?.validated) {
            color = 'text-success';
        } else if (instance?.isClose()) {
            color = 'text-muted';
        }

        return color;
    }

    const handleValidated = async (e, cell) => {
        e.preventDefault();
        e.stopPropagation();
        const rem = cell.getRow().getData();

        if (rem.status.hasErrors()) {
            addMessage('This remittance month has error(s). Clear the error(s) to close the month','danger');
            return;
        }

        if (rem.status.config.canChangeStatus(rem, user)) {
            rem.status = rem.status.isValidated() ? RemittanceStatus.types.s : RemittanceStatus.types.v;
            await RemittanceService.updateValidatedStatus(rem).then(() => {
                cell.getRow().update({status: rem.status});
                cell.getTable().redraw(true);
            });
        }
    }

    const optionsSelected = async (e, cell) => {
        e.preventDefault();
        e.stopPropagation();
        const cellRemittance = cell.getRow().getData();
        const tableRemittanceList = cell.getTable().getRows().map(row => row.getData());
        const remittance = tableRemittanceList.find(r => r.period.isSame(cellRemittance.period));
        const decemberRem = tableRemittanceList.find(r => r.period.isSameYear(remittance.period) && r.period.month === "12");
        const totalYEPayments = decemberRem?.payments?.nonRejectedPayments?.reduce((total, payment) => 
            total + payment.distribution.getDistributionForPeriod(remittance.period).reduce((distTotal, dist) => distTotal += dist.amount, 0)
        , 0);
        if (!remittance.shouldRecalculate) {
            const fileName = (remittance.validated ? "Statement" : "Summary") + `_${employer.code}_${remittance.period.value}.pdf`;
            return Promise.all([
                ConfigService.getConfig("bank"),
                ConfigService.getConfig("corporate"),
                AdjustmentService.getAdjustmentsForEmployer(employer.id),
            ]).then(([bank, corporate, adjustments]) =>
                downloadPdf(
                    <InvoiceOrStatementPdf
                        config={{ bank, corporate }}
                        employer={employer}
                        remittance={remittance}
                        isStatement={remittance.validated}
                        employerAdjustments={adjustments}
                        yearEndPaymentTotal={totalYEPayments}
                    />,
                    fileName
                )
            );
        }
    }

    const columns = new Table.Headers(
        Remittance, 
        'period, deductions, erContribs, voluntary, solvency, totalAdjustments, interest, creditUsed, total, prevTotalOwing, prevBalance, totalPayments, totalOwing, balance, status'
    );
    columns.period.format = (val) => val.longDesc;
    columns.balance.format = renderAmount;
    columns.totalOwing.format = renderAmount;
    columns.prevBalance.format = renderAmount;
    columns.prevTotalOwing.format = renderAmount;
    columns.creditUsed.description = "Employer Contributions used to offset credit";
    columns.total.className = 'table-col-separator';
    columns.status = {
        align:"center",
        format: remittanceStateIcon, 
        cellClick: variant === 'displayOnly' ? '' : handleValidated,
        width: 60,
        title: 'Status',
    };
    if (variant !== "displayOnly") {
        columns.actions = {
            width: 60,
            align: "center",
            cellClick: optionsSelected,
            format: actionIcon,
            headerSort: false,
        };
    }
    
    const formatYearEnd = (row) => {
        if (row.getData().period.yearEnd) {
            const findCell = row.getCells().find(cell => cell.getValue().yearEnd);
            findCell.getElement().className += ' tabulator-cell-accent';
        }
    }

    const exportColumns = new Table.Headers(
        Remittance, 
        'period, deductions, erContribs, voluntary, solvency, totalAdjustments, interest, creditUsed, total, prevTotalOwing, prevBalance, totalPayments, totalOwing, balance, status'
    );
    exportColumns.period.format = (val) => val.value;
    exportColumns.deductions.disableFormat = true;
    exportColumns.erContribs.disableFormat = true;
    exportColumns.voluntary.disableFormat = true;
    exportColumns.interest.disableFormat = true;
    exportColumns.creditUsed.disableFormat = true;
    exportColumns.prevTotalOwing.disableFormat = true;
    exportColumns.totalAdjustments.disableFormat = true;
    exportColumns.prevBalance.disableFormat = true;
    exportColumns.total.disableFormat = true;
    exportColumns.totalOwing.disableFormat = true;
    exportColumns.balance.disableFormat = true;
    exportColumns.totalPayments.disableFormat = true;
    exportColumns.status = {
        format: (value, instance) => {
            return value?.key || instance?.status?.key || '';
        }, 
        title: 'Status',
        name: "status"
    };

    if (isEmptyObject(user)) return null;

    return (
        <Table id='employer-consolidation-table'
            enableDownloadCsv={enableDownloadCsv}
            enableDownloadJson={enableDownloadJson}
            exportColumns={exportColumns}
            exportFileName={exportFileName}
            className='header-no-gap'
            columns={columns} 
            data={remittances.all} 
            dataKey='period' 
            desc
            onSelect={onSelect}
            sort='period'
            selectable
            rowFormatter={formatYearEnd}
            fitHeightToContent
        />
    );
}

export default RemittanceBalance;