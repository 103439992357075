import {  RefMap, Definition } from '../../../framework/infra'
import { round } from '../../../framework/utils/helper'
import Contribution from './Contribution'
import ContributionType from './ContributionType'

export default class Contributions extends RefMap {
	get reg() { return this.getAmount('REG') }
    set reg(val) { this.setAmount('REG', val) }
	
	get vol() { return this.getAmount('VOL')  }
	set vol(val) { this.setAmount('VOL', val) }
	
	get mat() { return this.getAmount('MAT') }
	set mat(val) { this.setAmount('MAT', val) }
	
	get ltd() { return this.getAmount('LTD') }
	set ltd(val) { this.setAmount('LTD', val) }
	
	get slf() { return this.getAmount('SLF') }
    set slf(val) { this.setAmount('SLF', val) }
	
	get total() { 
		return this.sum(
			this.all.filter(contribution => 
				contribution.type !== ContributionType.default
			)
		);
	}
	get deemed() { return this.sum(this.filter(contr => contr.type.isDeemed())) }
	get deductions() { return this.sum(this.filter(contr => !contr.type.isVoluntary())) }
	get excludesVoluntary() { return this.deductions }
	
	
	getAmount(type) { return this[type] ? this[type].amount : 0 }
	setAmount(type, val) {
		if (this[type]) this[type].amount = val
        else if(val) this.push(new Contribution({ amount: val, type: type }))
	}
	addAmount(type, val = 0) {
		if (this[type]) this[type].amount = round(this[type].amount + val)
        else this.push(new Contribution({ amount: val, type: type }))
		return this
	}
	sum(contributions) { return round(contributions.reduce((sum, contribution) => { sum += contribution.amount; return sum }, 0))}

	add(contributions) {
		contributions.forEach(contribution => {
			if (contribution) this.addAmount(contribution.type.key, contribution.amount)
		})
		return this
	}

	addAjdContrib(adj){
		this.addAmount('REG', adj.employeeReg)
		this.addAmount('VOL', adj.employeeVol)
		this.addAmount('MAT', adj.employeeMat)
		this.addAmount('LTD', adj.employeeLtd)
		this.addAmount('SLF', adj.employeeSlf)
	}

	round() { this.forEach(contr => contr.amount = round(contr.amount)) }
}

Contributions.ref = Contribution
Contributions.definitions = {
	reg: { abstract: true, type: Definition.types.AMOUNT, text: 'Regular'},
    vol: { abstract: true, type: Definition.types.AMOUNT, text: 'Voluntary'},
    mat: { abstract: true, type: Definition.types.AMOUNT, text: 'Maternity'},
    ltd: { abstract: true, type: Definition.types.AMOUNT, text: 'Long Term'},
	slf: { abstract: true, type: Definition.types.AMOUNT, text: 'Self'},
	total: { abstract: true, type: Definition.types.AMOUNT, text: 'Total Contributions'},
	deemed: { abstract: true, type: Definition.types.AMOUNT, text: 'Deemed'},
	deductions: { abstract: true, type: Definition.types.AMOUNT, text: 'Deductions (Excl. Vol)'},
	excludesVoluntary: { abstract: true, type: Definition.types.AMOUNT, text: 'Total (Excludes Voluntary)'},
}

Contributions.sum = function(contributionsToAdd) {
	return contributionsToAdd.reduce((sum, contrib) => {
		if (contrib) sum.add(contrib)
		return sum
	}, new Contributions())
}
