import RefList from './RefList'
import RefMessage from './RefMessage'

export default class RefMessages extends RefList {
    add(code, params) { 
        if(!this.hasMessage(code)) {
            this.pushNew({code: code, params: params})
        }
    }

    hasMessage(code) { return this.find(message => message.code === code) }


    static definitions = {
        last: {abstract: true, ref: RefMessage, text: 'Message'}
    }
}
