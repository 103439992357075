/**
 * Business logic for importing members
 */
import uuid from 'uuid/v4';
import moment from 'moment';

import Import from "../../../framework/infra/business/Import";
import ImportMessage from "../../../entities/import/ImportMessage";
import ImportMessageFactory from "../../../entities/import/ImportMessageFactory";
import ImportChangeLogger from "../../../entities/import/ImportChangeLogger";
import PersonService from "../../../services/person/PersonService";
import MembershipService from "../../../services/membership/MembershipService";
import ParticipationService from "../../../services/membership/ParticipationService";
import EmploymentService from "../../../services/employer/EmploymentService";
import EmployerService from "../../../services/employer/EmployerService";
import MemberImportSchema from "./MemberImportSchema";
import Person from "../../../entities/person/Person";
import Membership from "../../../entities/membership/Membership";
import Employment, { EMPLOYMENT_SOURCE } from "../../../entities/employment/Employment";
import EmploymentBusiness from "../../../business/EmploymentBusiness";
import { EVENT_SOURCE } from "../../../framework/infra/model/RefEvent";
import { EmploymentEvent } from '../../../entities/employment';
import { ParticipationEvent } from '../../../entities/membership';
import { assignSimpleProps, assignHistoricalProps, getEvents, updateOrAddEvent } from '../../../framework/utils/importHelper';
import { isValueUndefined } from '../../../framework/utils/helper';

class MemberImportBusiness extends Import {
    
    static TEMPLATE_NAME = 'Member-Upload-template.xlsx';
    
    constructor() {

        const messageFactory = new ImportMessageFactory();

        // Register all possible messages

        // Error, showstopper
        messageFactory.register('MISSING_SIN', 'No SIN provided.', ImportMessage.TYPE.ERROR);
        messageFactory.register('INVALID_EMPLOYER_CODE', 'Invalid Employer Code.', ImportMessage.TYPE.ERROR);
        messageFactory.register('INVALID_STATUS', 'Invalid status value.', ImportMessage.TYPE.ERROR);
        messageFactory.register('MISSING_HIRE_DATE', 'Hire date is required for new employments.', ImportMessage.TYPE.ERROR);
        messageFactory.register('DUPLICATE_SIN', 'This SIN already appears in this file.', ImportMessage.TYPE.ERROR);
        messageFactory.register('MISSING_EMPLOYER_CODE', 'Employer Code Missing.', ImportMessage.TYPE.ERROR);
        messageFactory.register('INVALID_PERSON_INFO', 'Invalid person information. ({numberOferrors}) errors found.', ImportMessage.TYPE.ERROR);
        messageFactory.register('EMPLOYMENT_CREATION_FAILED', 'Failed to create employment.', ImportMessage.TYPE.ERROR);

        // Danger, showstopper potential dangerous changes
        messageFactory.register('DOB_CHANGED', 'Date of birth is different. Changes will not be saved through eligibility upload. Update manually.', ImportMessage.TYPE.DANGER);
        messageFactory.register('HIRED_DATE_CHANGED', 'Hired date is different. Changes will not be saved through eligibility upload. Update manually.', ImportMessage.TYPE.DANGER);
        messageFactory.register('JOIN_DATE_CHANGED', 'Join date is different. Changes will not be saved through eligibility upload. Update manually.', ImportMessage.TYPE.DANGER);
        messageFactory.register('EMPLOYEE_IN_MULTIPLE_FILES', 'Employee appears in multiple upload files. Possible combined eligibility. Manual verification required.', ImportMessage.TYPE.DANGER);

        // Warning, attention needed
        messageFactory.register('PERSON_TO_CREATE', 'A new person will be created with ({numberOfchanges}) fields.', ImportMessage.TYPE.WARNING);
        messageFactory.register('PERSON_CREATED', 'A new person was created with ({numberOfchanges}) fields.', ImportMessage.TYPE.WARNING);
        messageFactory.register('EMPLOYMENT_TO_CREATE', 'A new employment will be created with ({numberOfchanges}) fields.', ImportMessage.TYPE.WARNING);
        messageFactory.register('EMPLOYMENT_CREATED', 'A new employment was created with ({numberOfchanges}) fields.', ImportMessage.TYPE.WARNING);
        messageFactory.register('PARTICIPATION_TO_CREATE', 'A new participation will be created.', ImportMessage.TYPE.WARNING);
        messageFactory.register('PARTICIPATION_CREATED', 'A new participation was created.', ImportMessage.TYPE.WARNING);

        // Info, non critical
        messageFactory.register('PERSON_TO_UPDATE', 'Person to be updated. ({numberOfchanges}) fields will be updated', ImportMessage.TYPE.INFO);
        messageFactory.register('PERSON_UPDATED', 'Person updated. ({numberOfchanges}) fields were updated', ImportMessage.TYPE.INFO);
        messageFactory.register('EMPLOYMENT_TO_UPDATE', 'Employment to be updated. ({numberOfchanges}) fields will be updated', ImportMessage.TYPE.INFO);
        messageFactory.register('EMPLOYMENT_UPDATED', 'Employment updated. ({numberOfchanges}) fields were updated', ImportMessage.TYPE.INFO);
        messageFactory.register('PARTICIPATION_TO_UPDATE', 'Participation to be updated. ({numberOfchanges}) fields will be updated', ImportMessage.TYPE.INFO);
        messageFactory.register('PARTICIPATION_UPDATED', 'Participation updated. ({numberOfchanges}) fields were updated', ImportMessage.TYPE.INFO);
        messageFactory.register('EMPLOYMENT_TASK', 'Employment task: {task}', ImportMessage.TYPE.INFO);

        super({
            templateName: MemberImportBusiness.TEMPLATE_NAME,
            rowSchema: MemberImportSchema,
            importMessageFactory: messageFactory,
        });
    }

    /**
     * Initialize the import context, a global context for the import
     * @param {ImportOptions} options - The import options
     * @returns {Object} - The import context
     */
    async _initImportContext(options) {
        const importContext = {
            employers: await EmployerService.getEmployers(),
            processedSins: []
        }
        return importContext;
    }

    /**
     * Is called by the _processImport method to process each row in the file
     * Main logic for processing each row is implemented here
     * @param {Object} importContext - The global import context
     * @param {number} rowIndex - The index of the row in the file
     * @param {Array<string>} row - The row data
     * @param {ImportRowDetail} rowDetail - The row detail for results
     * @param {ImportOptions} options - The import options
     * @returns {Promise<ImportRowDetail>} - The processed row detail
     */
    async _processRow(importContext, rowIndex, row, rowDetail, options) {
        const employer = importContext.employers.find(x=> x.code === row.employerCode);

        this._preProcessValidation(row, rowDetail, importContext, rowIndex, employer);

        if (rowDetail.hasErrors) return rowDetail;

        let person = await PersonService.getPersonBySin(row.sin);
        let membership;

        if (!person) {
            person = await this._createPerson(row, rowDetail, options);
            //if person is null, it means there was an error in the person creation
            if (!person) return rowDetail;

            membership = new Membership();
            membership.person = person;
        } else {
            // Map person changes
            await this._mapPersonChanges(person, row, rowDetail, options);
            membership = await MembershipService.get(person.id, { includeEmployments: true });

            // set redirect route for easy navigation in the results page
            rowDetail.redirect = '/member/' + person.id;
        }

        let lastParticipation = membership.lastParticipation;
        let lastParticipationNo = lastParticipation?.no;
        let employment;
        
        if (lastParticipation) {
            employment = lastParticipation.employments.find(employment => employment.employer.code === employer.code);
        }
        
        // determine if we need a new employment based on the participation status
        const createNewEmployment = !lastParticipation || !employment || (employment && employment.participation.isPendingOrClosed());
        
        if (createNewEmployment) {
            employment = await this._createEmployment(person, employer, membership, row, rowDetail, options);
            lastParticipation = employment.participation;
        } else {
            // Update the employment
            await this._mapEmploymentChanges(employment, row, rowDetail, options);
        }

        const isNewParticipation = lastParticipationNo !== employment.participation.no;
        this._mapParticipationChanges(lastParticipation, isNewParticipation, employment, row, rowDetail, options);

        importContext.processedSins.push(row.sin);

        return rowDetail;
    }


    //List of simple properties that can be directly assigned to the person
    _personPropList = ['firstName', 'lastName', 'gender', 'lng'];
    //List of simple properties that can be directly assigned to the employment
    _employmentPropList = ['noEmp'];
    //List of historical properties that can be assigned to the employment
    _employmentHistoricalPropList = ['isN', 'isTP', 'isCQ', 'workSch', 'employmentType'];
    //List of properties that can be assigned to the employment events
    _employmentEventPropList = [
        { code: 'hrd', prop: 'hiredDate' }, 
        { code: 'psd', prop: 'payrollStartDate' }
    ];
    //List of properties that can be assigned to the participation events
    _participationEventPropList = [
        { code: 'metEligDate', prop: 'eligibilityDt' }, 
        { code: 'metElig', prop: 'joinDt' }, 
        { code: 'inegDes', prop: 'nonEligibilityDt' }
    ];

    /**
     * Pre-process validation, run before the row is processed with basic validation
     * @param {Object} row - The row data
     * @param {ImportRowDetail} rowDetail - The row detail
     * @param {Object} importContext - The import context
     * @param {number} rowIndex - The index of the row in the file
     * @param {Employer} employer - The employer of the person
     */
    _preProcessValidation = (row, rowDetail, importContext, rowIndex, employer) => {
        if (!employer && !rowDetail.hasError('MISSING_EMPLOYER_CODE')) {
            rowDetail.addMessage(this.importMessageFactory.create('INVALID_EMPLOYER_CODE', {employerCode: row.employerCode}));
        }

        if (row.sin && importContext.processedSins.includes(row.sin)) {
            rowDetail.addMessage(this.importMessageFactory.create('DUPLICATE_SIN', {sin: row.sin}));
        }

        if (row.firstName || row.lastName) {
            rowDetail.title = `Row ${rowIndex}: ${row.firstName} ${row.lastName} (${row.sin})`;
        }
    }

    /**
     * Create a new person
     * @param {Object} row - The row
     * @param {ImportRowDetail} rowDetail - The row detail
     * @param {ImportOptions} options - The import options
     * @returns {Person} - The new person
     */
    async _createPerson(row, rowDetail, options) {

        const person = new Person({ id: uuid()});
        const changes = new ImportChangeLogger();

        assignSimpleProps(person, row, [...this._personPropList, 'dob', 'sin'], MemberImportSchema, changes);

        var errors = PersonService.validate(person);
        if (errors.length > 0) {
            rowDetail.addMessage(this.importMessageFactory.create('INVALID_PERSON_INFO', {numberOferrors: errors.length}, errors));
            return null;
        }

        if (options.commit) {
            await PersonService.create(person);
        }

        rowDetail.addMessage(this.importMessageFactory.create(options.commit ? 'PERSON_CREATED' : 'PERSON_TO_CREATE', {numberOfchanges: changes.total()}, changes.toStringList()));
    
        return person;
    }

    /**
     * Map person changes
     * @param {Person} person - The person
     * @param {Object} row - The row
     * @param {ImportRowDetail} rowDetail - The row detail
     * @param {ImportOptions} options - The import options
     */
    async _mapPersonChanges(person, row, rowDetail, options) {

        const changes = new ImportChangeLogger();
        const personPropList = [...this._personPropList];

        // Only update the dob if it is not already set and the row has a dob
        if (!isValueUndefined(person.dob) && !isValueUndefined(row.dob)) {
            const personDobMoment = moment(person.dob);
            if (!personDobMoment.isSame(row.dob, 'day')) {
                rowDetail.addMessage(this.importMessageFactory.create('DOB_CHANGED', {dob: row.dob}, null, true));
            }
        } else {
            personPropList.push('dob');
        }

        assignSimpleProps(person, row, personPropList, MemberImportSchema, changes);

        if (changes.total() > 0) {
            if (options.commit) {
                await PersonService.update(person);
            }
            rowDetail.addMessage(this.importMessageFactory.create(options.commit ? 'PERSON_UPDATED' : 'PERSON_TO_UPDATE', {numberOfchanges: changes.total()}, changes.toStringList()));
        }

        return changes;
    }

    /**
     * Create a new employment
     * @param {Person} person - The person to be employed
     * @param {Employer} employer - The employer of the person
     * @param {Membership} membership - The membership of the person
     * @param {Object} row - The row data
     * @param {ImportRowDetail} rowDetail - The row detail
     * @param {ImportOptions} options - The import options
     * @returns {Employment} - The created employment
     */
    async _createEmployment(person, employer, membership, row, rowDetail, options) {

        let employment = new Employment();
        employment.employer = employer;
        employment.participation.membership = membership;

        const changes = new ImportChangeLogger();
        const events = getEvents(EmploymentEvent, row, this._employmentEventPropList, EVENT_SOURCE.FILE.key);

        // Handle hired date, required
        if (isValueUndefined(events.hiredDate)) {
            rowDetail.addMessage(this.importMessageFactory.create('MISSING_HIRE_DATE', {}, null, true));
            return employment;
        }

        updateOrAddEvent(employment, employment.getHiredEvent(), events.hiredDate, changes);

        const employmentCreationResults = await EmploymentService.createEmployment(employment, EMPLOYMENT_SOURCE.IMPORT , {noCommit: !options.commit, eventsSource: EVENT_SOURCE.FILE.key});
        let isNewEmployment = false;
        if (employmentCreationResults.employment) {
            employment = employmentCreationResults.employment;

            isNewEmployment = employmentCreationResults.newEmploymentCreated;
            
            if (employmentCreationResults.warning) {
                rowDetail.addMessage(this.importMessageFactory.createUnregistered("Employment Flow Warning: " + employmentCreationResults.warning, ImportMessage.TYPE.WARNING, null, true));
            }

            EmploymentBusiness.validate(employment);
            for (const task of employment.tasks.all) {
                rowDetail.addMessage(this.importMessageFactory.create('EMPLOYMENT_TASK', {task: task.desc}, null, true));
            }
        } else {
            console.error(employmentCreationResults);
            rowDetail.addMessage(this.importMessageFactory.create('EMPLOYMENT_CREATION_FAILED'));
        }

        // ** at this point, depending on the employment creation results, the employment may be an existing employment or a new one
        const simpleChanges = await this._assignEmploymentValues(employment, row, events);

        // Special condition, as the createEmployment function creates the employment and commits the change, but we need to assign the new values here
        // as sometimes, in cases of return to work, we get back an existing employment, and we want to update the existing employments values instead.
        if (simpleChanges.total() > 0) {
            if (options.commit) {
                await EmploymentService.updateEmployment(employment);
            }
            changes.merge(simpleChanges);
        }

        if (isNewEmployment) {
            rowDetail.addMessage(this.importMessageFactory.create(options.commit ? 'EMPLOYMENT_CREATED' : 'EMPLOYMENT_TO_CREATE', {numberOfchanges: changes.total()}, changes.toStringList()));
        } else {
            rowDetail.addMessage(this.importMessageFactory.create(options.commit ? 'EMPLOYMENT_UPDATED' : 'EMPLOYMENT_TO_UPDATE', {numberOfchanges: changes.total()}, changes.toStringList()));
        }

        return employment;
    }

    /**
     * Assign employment values
     * @param {Employment} employment - The employment
     * @param {Object} row - The row
     * @param {Object} events - The events
     * @returns {ImportChangeLogger} - The changes
     */
    async _assignEmploymentValues(employment, row, events = {}) {
        const changes = new ImportChangeLogger();

        assignSimpleProps(employment, row, this._employmentPropList, MemberImportSchema, changes);
        assignHistoricalProps(employment, row, this._employmentHistoricalPropList, MemberImportSchema, changes);

        // Handle payroll start date, optional
        if (!isValueUndefined(events.payrollStartDate)) {
            updateOrAddEvent(employment, employment.getPayrollStartDateEvent(), events.payrollStartDate, changes);
        }

        return changes;
    }

    /**
     * Map employment changes
     * @param {Employment} employment - The employment to be updated
     * @param {Object} row - The row data
     * @param {ImportRowDetail} rowDetail - The row detail
     * @param {ImportOptions} options - The import options
     */
    async _mapEmploymentChanges(employment, row, rowDetail, options) {
        const changes = new ImportChangeLogger();

        const statusKey = row.eventStatus;
        const eventPropList = [...this._employmentEventPropList];
        if (statusKey) {
            eventPropList.push({code: statusKey, prop: 'eventStatusDate'});
        }

        const events = getEvents(EmploymentEvent, row, eventPropList, EVENT_SOURCE.FILE.key);

        // Handle status updates
        const statusEvent = events.eventStatusDate;
        if (statusEvent) {
            const eventAtDate = employment.eventStatuses.getAt(statusEvent.ets);
            if (eventAtDate.code !== statusEvent.code) {
                changes.logChange('Status Event', eventAtDate.desc, statusEvent.desc);
                changes.logChange('Status Event Effective Date', eventAtDate.effDt, statusEvent.effDt);
                if (!isValueUndefined(statusEvent.cmt)) {
                    changes.logChange('Status Event Comment', eventAtDate.cmt, statusEvent.cmt);
                }

                employment.addEvent(statusEvent);
            }
        }

        // Handle hired date
        updateOrAddEvent(employment, employment.getHiredEvent(), events.hiredDate, changes, {
            canUpdate: false,
            updateWarning: this.importMessageFactory.create('HIRED_DATE_CHANGED', {hiredDate: row.hiredDate}, null, true),
            rowDetail,
        });

        changes.merge(await this._assignEmploymentValues(employment, row, events));

        if (changes.total() > 0) {
            if (options.commit) {
                await EmploymentService.updateEmployment(employment);
            }
            rowDetail.addMessage(this.importMessageFactory.create(options.commit ? 'EMPLOYMENT_UPDATED' : 'EMPLOYMENT_TO_UPDATE', {numberOfchanges: changes.total()}, changes.toStringList()));
        }
    }

    /**
     * Map participation changes
     * @param {Participation} participation - The participation to be updated
     * @param {boolean} isNewParticipation - Whether the participation is new from the employment creation flow
     * @param {Employment} employment - The employment being updated
     * @param {Object} row - The row data
     * @param {ImportRowDetail} rowDetail - The row detail
     * @param {ImportOptions} options - The import options
     */
    async _mapParticipationChanges(participation, isNewParticipation, employment, row, rowDetail, options) {
        const changes = new ImportChangeLogger();
        const events = getEvents(ParticipationEvent, row, this._participationEventPropList, EVENT_SOURCE.FILE.key);

        // Handle join date
        updateOrAddEvent(participation, participation.getActiveStatusEvent(), events.joinDt, changes, {
            canUpdate: false,
            updateWarning: this.importMessageFactory.create('JOIN_DATE_CHANGED', {joinDate: row.joinDt}, null, true),
            rowDetail
        });

        // Handle Eligibility Date
        let eligEvent;  
        if (!isValueUndefined(row.eligibilityDt)) {
            eligEvent = participation.getEligbilityByYearAndEmployer(row.eligibilityDt.year(), employment.employer);
            updateOrAddEvent(participation, eligEvent, events.eligibilityDt, changes, {
                canUpdate: true,
                eventParams: { openEmployment: employment }
            });
        }

        const isEnrolled = participation.events.find(event => event.config.isEnrollEvent);
        if (events.eligibilityDt && !eligEvent && !isEnrolled) {
            //warn for multiple ineg events in the same year
            const inegEvents = participation.events.filter(event => event.status.isIneligible() && event.effMoment.year() === events.eligibilityDt.effMoment.year());
            if (inegEvents.length > 1) {
                rowDetail.addMessage(this.importMessageFactory.create('EMPLOYEE_IN_MULTIPLE_FILES', null, null, true));
            }
        }

        // Handle Non Eligibility Date
        // check if participation is already enrolled, if so, do not update the non eligibility date
        if (!isEnrolled && !isValueUndefined(events.nonEligibilityDt)) {
            const nonEligDateYear = row.nonEligibilityDt.year();
            const nonEligEvent = participation.events.find(event => event.code === 'inegDes' && event.effMoment.year() === nonEligDateYear);
            updateOrAddEvent(participation, nonEligEvent, events.nonEligibilityDt, changes, {
                canUpdate: true,
                eventParams: { openEmployment: employment }
            });
        }   

        if (isNewParticipation) {
            // extra rule here since employment creation flow automatically marks as not ELIGIBLE, this might be false if an ELIG event was added
            if (participation.events.find(event => event.isEligibleEvent())) {
                participation.events.pullFilter(event => !event.status.isIneligible());
            }
            
            rowDetail.addMessage(this.importMessageFactory.create(options.commit ? 'PARTICIPATION_CREATED' : 'PARTICIPATION_TO_CREATE', {numberOfchanges: changes.total()}, changes.toStringList()));
        } else {

            if (changes.total() > 0) {
                rowDetail.addMessage(this.importMessageFactory.create(options.commit ? 'PARTICIPATION_UPDATED' : 'PARTICIPATION_TO_UPDATE', {numberOfchanges: changes.total()}, changes.toStringList()));
            }       
        }

        if (options.commit) {
            await ParticipationService.updateParticipation(participation, {eventsSource: EVENT_SOURCE.FILE.key});
        }
    }
}

export default new MemberImportBusiness();

