import React from 'react'
import ReactDOM from 'react-dom'
import { saveAs } from 'file-saver'
import TabulatorTable from 'tabulator-tables'
import _ from 'lodash'
import { renderComponent } from '../utils/renders'
import { Button } from "../../framework/controls"; /**
* Configuration of the column in Tabulator.
* @typedef TabulatorColumn
* @type {object}
* @property {string} title - the title that will be displayed in the header for this column. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {boolean | string | (() => string) | undefined} headerTooltip - sets the on hover tooltip for the column header. See {@link https://tabulator.info/docs/4.9/format#tooltips}
* @property {boolean | string | (() => string) | undefined} field - the key for this column in the data array. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {TabulatorColumn[] | undefined} columns - group column headers together to create complex multi-row table headers. To group columns, you need to add a column group object in the column definition array. You must give a column group a title and add the grouped column objects into the columns property of the group. See {@link https://tabulator.info/docs/4.9/columns#groups}
* @property {string | undefined} cssClass -  sets css classes on header and cells in this column. Value should be a string containing space separated class names. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {boolean | undefined} headerSort - flag to sort by clicking on the header. See {@link https://tabulator.info/docs/4.9/format#definition}
* @property {((cell, formatterParams, onRendered) => (string | Node)) | "plaintext" | "textarea" | "html" | "money" | "image" | "link" | "datetime" | "datetimediff" | "tickCross" | "color" | "star" | "traffic" | "progress" | lookup" | "buttonTick" | "buttonCross" | "rownum" | "handle" | "rowSelection" | "responsiveCollapse" | undefined} formatter - set how you would like the data to be formatted. See {@link https://tabulator.info/docs/4.9/format#format}
* @property {"left" | "center" | "right" | undefined} hozAlign - sets the horizontal text alignment for this column. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {"left" | "center" | "right" | undefined} headerHozAlign - sets the horizontal text alignment for this columns header title. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {((values, data, calcParams) => number) | "avg" | "max" | "min" | "sum" | "concat" | "count" | undefined} topCalc - the column calculation to be displayed at the top of this column. See {@link https://tabulator.info/docs/4.9/column-calcs}
* @property {boolean | string | (() => string) | undefined} topCalcFormatter - apply formatters to any calculation cells. See {@link https://tabulator.info/docs/4.9/column-calcs#format}
* @property {((e, cell) => any) | undefined} cellClick - callback for when user clicks on a cell in this column. See {@link https://tabulator.info/docs/4.9/callbacks#cell}
*/

/**
* Configuration of the column that will be transformed to the configuration for Tabulator.
* @typedef TabulatorPropsColumn
* @type {object}
* @property {boolean | undefined} hideIfEmpty - Flag to hide the column if no rows have value for this column.
* @property {string} title - the title that will be displayed in the header for this column. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {boolean | string | (() => string) | undefined} description - sets the on hover tooltip for the column header. Sets the `headerTooltip` in `TabulatorColumn`. See {@link https://tabulator.info/docs/4.9/format#tooltips}
* @property {boolean | string | (() => string) | undefined} name - the key for this column in the data array. Sets the `field` in `TabulatorColumn`. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {TabulatorColumn[] | undefined} columns - group column headers together to create complex multi-row table headers. To group columns, you need to add a column group object in the column definition array. You must give a column group a title and add the grouped column objects into the columns property of the group. See {@link https://tabulator.info/docs/4.9/columns#groups}
* @property {string | undefined} className -  sets css classes on header and cells in this column. Value should be a string containing space separated class names. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {boolean | undefined} headerSort - flag to sort by clicking on the header. See {@link https://tabulator.info/docs/4.9/format#definition}
* @property {((cell, formatterParams, onRendered) => (string | Node)) | "plaintext" | "textarea" | "html" | "money" | "image" | "link" | "datetime" | "datetimediff" | "tickCross" | "color" | "star" | "traffic" | "progress" | lookup" | "buttonTick" | "buttonCross" | "rownum" | "handle" | "rowSelection" | "responsiveCollapse" | undefined} format - set how you would like the data to be formatted. Sets the `formatter` in `TabulatorColumn`. See {@link https://tabulator.info/docs/4.9/format#format}
* @property {"left" | "center" | "right" | undefined} hozAlign - sets the horizontal text alignment for this column. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {"left" | "center" | "right" | undefined} headerHozAlign - sets the horizontal text alignment for this columns header title. See {@link https://tabulator.info/docs/4.9/columns#definition}
* @property {((values, data, calcParams) => number) | "avg" | "max" | "min" | "sum" | "concat" | "count" | undefined} topCalc - the column calculation to be displayed at the top of this column. See {@link https://tabulator.info/docs/4.9/column-calcs}
* @property {boolean | string | (() => string) | undefined} topCalcFormatter - apply formatters to any calculation cells. See {@link https://tabulator.info/docs/4.9/column-calcs#format}
* @property {((e, cell) => any) | undefined} cellClick - callback for when user clicks on a cell in this column. See {@link https://tabulator.info/docs/4.9/callbacks#cell}
*/

/**
* Tabulator
* 
* Props:
* - `columns`: Configuration of the columns. Type: `TabulatorPropsColumn[]`
* - `exportFileName`: Name of the export file (without file extension). Optional. Default: `'data'`.
* - `enableDownloadCsv`: flag to enable the CSV export. Optional. Default: `false`.
* - `enableDownloadJson`: flag to enable the Json export. Optional. Default: `false`.
* - `exportColumns`: Columns definitions for exporting the table. Type (Table.Headers or array or object): `Table.Headers | ExportColumnConfig[] | { [key: string]: ExportColumnConfig }` (and `ExportColumnConfig` type is `{title?: string | undefined; name?: string | undefined; format?: ((value: any, instance: any) => any) | undefined; wrapCsvValueInQuotes?: boolean | undefined; disableFormat?: boolean | undefined; overrideText?: string | undefined; field?: string | undefined}`)
* - `convertDataToObjects`: Flag to convert data (that might be an array of class instances with getters, which are not supported by Tabulator) to an array of plain objects. Optional. Default: `false`.
*/
export default class Tabulator extends React.Component {
    componentDidMount() {
        const { id, data, desc, sort, selected, initialSort, onTableLoad, hideEmptyColumns, resizableColumns, className, rowFormatter, dataKey, dataTree, dataTreeStartExpanded, dataTreeBranchElement, groupBy, groupText, groupStartOpen, fitHeightToContent, selectable, noPlaceHolder, initialHeaderFilter, removeDuplicateRows } = this.props;
         /** @type {Array<TabulatorColumn | TabulatorPropsColumn>} */
	    const columns = this.props.columns;

        this.columns = this.convertColumns(hideEmptyCols(columns, data, hideEmptyColumns))
        this.dataCopy = data //HACK use to refresh tabulator if data was modified

        var pageBodyElement = document.getElementsByClassName('page-body')[0] //use to set the temporary size of the tabulator to handle performance issues
        const element = ReactDOM.findDOMNode(this.refs[id])
        if (!element || !data || !this.columns) return
        this.selected = selected
        this.tabulator = new TabulatorTable(element, {
            data: removeDuplicateRows ? removeDuplicates(columns, data) : data,
            columns: this.columns,
            rowClick: this.handleSelect.bind(this),
            cellEditing: (event, cell, row) => {
                this.editing = true
            },
            cellEdited: (event, cell, row) => {
                this.editing = false
            },
            cellEditCancelled: (event, cell, row) => {
                this.editing = false
            },
            index: dataKey || 'id',
            initialSort: initialSort || [{ column: sort, dir: desc ? 'desc' : 'asc' }],
            selectable: selectable === false ? false : '1',
            height: !fitHeightToContent ? pageBodyElement.offsetHeight : null, //HACK if not set then it renders all rows and takes forever, we set to hight of page and we reduce after rendering
            layout: "fitColumns",
            resizableColumns: resizableColumns !== false,
            tooltipsHeader: true,
            placeholder: noPlaceHolder ? '' : "No Results Found",
            keybindings: {
                "navLeft": "37",
                "navRight": "39"
            },
            groupBy: groupBy,
            groupHeader: groupText,
            groupStartOpen: groupStartOpen,
            groupToggleElement: "header",

            rowFormatter: rowFormatter,
            dataTree: dataTree,
            dataTreeStartExpanded: dataTreeStartExpanded ?? false,
            dataTreeCollapseElement: renderComponent(<div className='icon-noun-dropdown tabulator-data-tree-control'/>), //HACK? to overwrite tree icon with text, I put them here to make every tree same but not sure if it's okay here
			dataTreeExpandElement: renderComponent(<div className='icon-noun-dropdown-up tabulator-data-tree-control'/>),
            dataTreeBranchElement: dataTreeBranchElement,
            initialHeaderFilter: initialHeaderFilter
        })
        this.tabulator.element.className = this.tabulator.element.className + ' ' + (className || '')
        this.handleSelect()

        //Handle tabulator height
        if(!fitHeightToContent) {
            var offsetHeight = pageBodyElement.offsetHeight + pageBodyElement.offsetTop
            var offsetTop = this.tabulator.element.offsetTop
            for(var el = this.tabulator.element; el && el !== pageBodyElement; el = el.parentElement) {
                if(el.className) {
                    if (el.className.startsWith('card') || el.className.startsWith('row')) offsetTop += el.offsetTop
                    if (el.className.startsWith('modal-content')) offsetHeight = el.offsetHeight - 4 //Investigate whay we need the -4
               }
            }
            this.tabulator.setHeight(offsetHeight - offsetTop);
        }
        
        onTableLoad && onTableLoad(this.tabulator)
    }
  
    /**
     * 
     * @param {object} row The table row (from `this.tabulator.getRows()`)
     * @param {object} exportCol The export column
	 * @param {TabulatorColumn[]} columns The table columns (from `this.tabulator.getColumns()`)
     * @param {any} defaultValue The default value if the cell has no value
     * @param {"csv" | "json"} format The format (used to find relevant options in the export column config)
     * @returns 
     */
    getExportCell(row, exportCol, columns, defaultValue, format){
        /** the table column with same title as the export column */
        const col = columns.find(column => (exportCol.name && exportCol.name === column._column?.definition?.field) || (exportCol.title && exportCol.title === column._column?.definition?.title));
        /** the index of the column table with same title as the export column */
        const colIndex = columns.findIndex(column => exportCol.title && exportCol.title === column._column?.definition?.title);
        /** raw cell value from the data */
        const rawValue = (row._row?.data && col?._column?.definition?.field) ? _.get(row._row.data, col._column.definition.field) : undefined;
        /** cell value from the `getValue()` getter */
        const cellValue = row._row?.cells[colIndex]?.getValue?.() ?? row._row?.cells[colIndex];
        let value = rawValue;
        if(typeof exportCol?.format === 'function'  && !exportCol.disableFormat && row._row?.data) {
            try{
                /** cell value formatted with the format function */
                const formattedValue = exportCol.format(rawValue, row._row.data);
                if(formattedValue) value = formattedValue;
            } catch(err){
                console.error('Tabulator getExportCell, format error', col?._column?.definition?.field, exportCol.title, err)
            }
        }
        if(format === 'csv' && exportCol.wrapCsvValueInQuotes && value) {
            value =`"${value}"`
        }
        const valueToExport = (value === null || value === undefined /* || value === false */) ? defaultValue : value;
        return valueToExport;
    }

    /**
     * 
     * @returns {object[]}
     */
    getValidExportColumns() {
        const { exportColumns } = this.props;
        if(!exportColumns) return [];
        /** @type {object[]} */
        const validExportColumns = (Array.isArray(exportColumns) ? [...exportColumns] :
            (typeof exportColumns === 'object'/*  || exportColumns instanceof Table.Headers */) ? Object.values(exportColumns) : [])
            .filter(x => !(typeof x === 'function' || Array.isArray(x)));
        return validExportColumns;
    }

    renderExport() {
        const { exportFileName, enableDownloadCsv, enableDownloadJson, exportColumns } = this.props;
        const validExportColumns = this.getValidExportColumns();
        if(!((enableDownloadCsv || enableDownloadJson) && Boolean(validExportColumns?.length))) {
            return null;
        }
        return <>
            <div className="d-flex flex-row" style={{gap: '10px',}}>
                {enableDownloadCsv && <Button className="btn-secondary"
                    onClick={() =>{
                        // download function doesn't work because it uses cloneDeep and that crashes because of dependency loop
                        // this.tabulator?.download("csv", "data.csv");
                        /** @type {object[]} */
                        const rows = this.tabulator.getRows();
                        /** @type {TabulatorColumn[]} */
                        const columns = this.tabulator.getColumns();
                        /** @type {string[][]} */
                        const exportedCsvData = [
                        validExportColumns.map((exportCol, i) => `"${((exportCol.overrideText ? exportCol.overrideText : exportCol.title) || exportCol.name || exportCol.field || `col${i}`).replaceAll('\n', ' ')}"`),
                        ...rows.map(row => {
                            return validExportColumns.map((exportCol) => {
                                const valueToExport = this.getExportCell(row, exportCol, columns, '', 'csv');
                                return valueToExport;
                            })
                        })
                        ];
                        const blob = new Blob([exportedCsvData.map(row => row/* .map(cell => `"${cell}"`) */.join(',')).join('\n')], { type: 'text/csv' });
                        saveAs(blob, `${exportFileName || 'data'}.csv`)
                    }}
                >Download table (CSV)</Button>
                }
                {enableDownloadJson && <Button className="btn-secondary btnDownloadTabulatorJson"
                    onClick={() =>{
                        // download function doesn't work because it uses cloneDeep and that crashes because of dependency loop
                        // this.tabulator?.download("json", "data.json");
                        const rows = this.tabulator.getRows();
                        /** @type {TabulatorColumn[]} */
                        const columns = this.tabulator.getColumns();
                        const exportedJsonData = [
                            ...rows.map((row) => {
                                const rowObject = {};
                                validExportColumns.forEach((exportCol, i) => {
                                    /** the table column with same title as the export column */
                                    const col = columns.find(column => exportCol.title && exportCol.title === column._column?.definition?.title);
                                    const valueToExport = this.getExportCell(row, exportCol, columns, null, 'json');
                                    _.set(rowObject, col?._column?.definition?.field || exportCol.name || `col${i}`, valueToExport);
                                });
                                return rowObject;
                            })
                        ];
                        const blob = new Blob([JSON.stringify({data:exportedJsonData}, undefined, 2)], { type: 'application/json' });
                        saveAs(blob, `${exportFileName || 'data'}.json`)
                    }}
                >Download table (JSON)</Button>
                }
            </div>
        </>;
    }

    render() {
        const { id } = this.props;
        if (this.tabulator) this.handleTableChange();
        return <>
            {this.renderExport()}
            <div ref={ id } id={ id || 'tabulator' } />
        </>;
    }

    handleTableChange() {
        const scrollTop = this.tabulator.rowManager.element.scrollTop
        var { hideEmptyColumns, data } = this.props;
        /** @type {TabulatorColumn[]} */
        let columns = this.props.columns;
        const convertedData = this.props.removeDuplicateRows ? removeDuplicates(columns, data) : data;
        if (this.tabulator) {
            if (!_.isEqual(this.dataCopy, convertedData)) { //Data have changed 
                this.dataCopy = convertedData
                this.tabulator.setData(convertedData)
            }
            columns = hideEmptyCols(columns, convertedData, hideEmptyColumns)

            const compareA = this.columns.map(x=> ({name: x.name, description: x.description, title: x.title}))
            const compareB = columns.map(x=> ({name: x.name, description: x.description, title: x.title}))
            if (!_.isEqual(compareA, compareB)) { //Columns have changed
                this.columns = _.cloneDeep(columns)
                this.tabulator.setColumns(this.convertColumns(columns));
            }
            this.handleSelect()
        }
        this.tabulator.rowManager.element.scrollTop = scrollTop
    }

    handleSelect(e, row) {
        if (!this.tabulator || !row || !row.select) return
        const key = _.get(this, 'tabulator.options.index')
        const rowId = _.get(row, '_row.data.' + key)
        this.tabulator.deselectRow()
        if (row) {
            row.select()
            this.selected = rowId
            if (this.props.onSelect) this.props.onSelect(row._row.data)
        } else if (this.selected) {
            this.tabulator.getRows().forEach(row => { if (_.get(row, '_row.data.' + key) === this.selected) row.select() })
        }
    }

    /**
    * 
    * @param {TabulatorPropsColumn[]} cols 
    * @returns {TabulatorColumn[]}
    */
    convertColumns(cols) {
        return cols.map(col => {
            const convertedCol = {...col}
            convertedCol.headerTooltip = col.description
            convertedCol.field = col.name
            convertedCol.columns = col.columns && this.convertColumns(col.columns)
            convertedCol.cssClass = col.className
            convertedCol.minResizeWidth = col.minWidth
            convertedCol.maxResizeWidth = col.maxWidth
            convertedCol.headerSort = col.headerSort !== false
            
            if (col.format) {
                convertedCol.formatter = typeof col.format === "function" ? cell => col.format(cell.getValue(), cell._cell.row.data, cell) : col.format
            } else {
                convertedCol.formatter = cell => cell.getValue()
            }

            if (col.topCalc) {
                convertedCol.topCalc = col.topCalc
                convertedCol.topCalcFormatter = col.topCalcFormatter ? cell => col.topCalcFormatter(cell.getValue(), cell._cell.row.data) : null
            }

            convertedCol.cellClick = (event, cell) => {
                if (this.editing) event.stopPropagation()
                else if (col.cellClick) col.cellClick(event, cell)
            }
            return convertedCol
        })
    }
}

/**
 * 
 * @param {Array<TabulatorColumn | TabulatorPropsColumn>} cols 
 * @param {*} data 
 * @param {boolean | undefined} forceHide 
 * @returns 
 */
function hideEmptyCols(cols, data, forceHide) {
    return cols.filter(col => {
        if (col.columns && col.columns.length > 0) {
            return col.columns = hideEmptyCols(col.columns, data, forceHide)
        } else if (forceHide || col.hideIfEmpty) {
            return data.find(item => _.get(item, col.name))
        } else {
            return true
        }
    })
}

 /**
  * 
  * @param {Array<TabulatorColumn | TabulatorPropsColumn>} columns : ;
  * @param {*} data 
  * @returns 
  */
function removeDuplicates(columns, data) {
    const dataByKeys = data.reduce((rows, item) => {
        const key = []
        columns.forEach(col => {
            key.push(_.get(item, col.name))
        })
        rows[key] = item 
        return rows
    }, {})
    return Object.values(dataByKeys)
} 