/**
 * Represents a validation response for import field validation
 */
class ImportFieldValidationResponse {
    /**
     * @param {boolean} isValid - Whether the validation passed
     * @param {string} [errorKey] - The message key for the error if validation failed
     * @param {Object} [errorParams] - Parameters to be used in the error message
     */
    constructor(isValid, errorKey = null, errorParams = {}) {
        //validate inputs
        if (typeof isValid !== 'boolean') {
            throw new Error('isValid must be a boolean');
        }
        if (typeof errorKey !== 'string') {
            throw new Error('errorKey must be a string');
        }
        if (typeof errorParams !== 'object' && errorParams !== null && !Array.isArray(errorParams)) {
            throw new Error('errorParams must be an object or null');
        }

        this.isValid = isValid;
        this.errorKey = errorKey;
        this.errorParams = errorParams;
    }
}

export default ImportFieldValidationResponse;
