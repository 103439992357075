import React from 'react';
import CardMessage from './CardMessage';

const CardContainer = ({ details, defaultText = 'No details.' }) => {
    return (
        <div className='transfer-detail'>
            {details?.length ? (
                details.map((detail) => (
                        <CardMessage key={detail.rowId} detail={detail} />
                    ))
            ) : (
                <span className='info-label-ghost'>
                    {!details ? defaultText : 'No details.'}
                </span>
            )}
        </div>
    );
};



export default CardContainer;