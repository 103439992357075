import { Period } from "../../../framework/utils";
import PreUpload from "./PreUpload";
import { useEffect, useMemo, useState, cloneElement } from "react";
import { Prompt, useParams } from "react-router-dom";
import { EarningType, Remittances } from "../../../entities";
import { EInput, Message, QuestionLarge } from "../../../framework/components";
import { Button, Form, Icon } from "../../../framework/controls";
import { useEmployerStore } from "../../../hooks/useEmployerStore";
import { RemittanceBusiness, RemittanceDetailBusiness } from "../../../business";
import { Col, Modal, Row, Tabs, Title as WarningTitle, FieldSet } from "../../../framework/containers";
import { RemittanceService, RemittanceDetailService, EmploymentService, EmployerService, AdjustmentService, ParticipationService } from "../../../services";
import { useHistory } from "react-router-dom";

import moment from "moment/moment";
import ReportsMenu from "./ReportsMenu";
import PaymentList from "../payments/PaymentList";
import RemittanceBalance from "./RemittanceBalance";
import EarningsImport from "../earnings/EarningsImport";
import Title from "../../../components/ContentHeaderTitle";
import ValidationTable from "./validations/ValidationTable";
import useNotification from "../../../hooks/useNotification";
import Loading from "../../../components/containers/Loading";
import DeductionsEarningsTable from "./DeductionsEarningsTable";
import YECertification from "./yeCertification/YECertification";
import AdjustmentList from "../../pension/adjustment/AdjustmentList";
import SummaryTable from "../../../framework/components/page/SummaryTable";
import TaskList from "../../member/TaskList";
import { EVENT_SOURCE } from "../../../framework/infra/model/RefEvent";

/**
 * 
 * @param {boolean | undefined} hasEvents 
 * @param {boolean | undefined} hasEmployments 
 * @returns {string} The message like "events and employments" or "events" or "employments" or ""
 */
const getEventsAndEmploymentsMessage = (hasEvents, hasEmployments) => {
    const messageValues = [];
    if (hasEvents) messageValues.push("events");
    if (hasEmployments) messageValues.push("employments");
    return messageValues.join(' and ')
}

const RemittanceDetailsPage = () => {
    
    let { employerCode, period } = useParams();
    const employerMapping = useEmployerStore((state) => state.employers);
    const employerId = employerMapping[employerCode]?.id; 
    const { addMessage } = useNotification();

    const history = useHistory();
    const [message, setMessage] = useState();
    const [fileName, setFileName] = useState();
    const [employer, setEmployer] = useState();
    const [isDirty, setIsDirty] = useState(false);
    const [newComment, setNewComment] = useState();
    const [remittances, setRemittances] = useState();
    const [warningPopUp, setWarningPopUp] = useState();
    const [summaryPopUp, setSummaryPopUp] = useState();
    const [uploadDetails, setUploadDetails] = useState();
    const [paymentsExport, setPaymentsExport] = useState();
    const [inspectionPopUp, setInspectionPopUp] = useState();
    const [summariesToShow, setSummariesToShow] = useState();
    const [activeTab, setActiveTab] = useState("contributions");
    const [adjustmentsExport, setAdjustmentsExport] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isYECertificationOpen, setIsYECertificationOpen] = useState(false);
    const [dedEarningsTableExport, setDedEarningsTableExport] = useState();
    const [isResetEventWarningOpen, setIsResetEventWarningOpen] = useState(false);
    const [isResetEventConfirmed, setIsResetEventConfirmed] = useState(false);

    let earningTypes = employer?.getActiveEarningTypes();
    if (period === "201912") earningTypes = [new EarningType({code: "y9",alias: "",label: "2019 Earnings", hoursOrRate: "hours" })].concat(earningTypes);

    const periodInstance = new Period(period);
    const remittance = useMemo(() => remittances?.find((rem) => rem.period.value === period).clone(), [remittances]);

    useEffect(() => {
        const getDetails = async () => {
            // If it's the first time to fetch the remittances or we need to refresh the data
            if (employerId && period) { 
                const employer = await EmployerService.getEmployer(employerId, { ignorePeopleFetch: true })
                
                // get top remittance summary 
                const refreshedRemittances = await RemittanceService.getRemittancesForPeriods("all", employer, {load: false});
                const adjustments = await AdjustmentService.getAdjustmentsForEmployer(employerId);

                refreshedRemittances.forEach(rem => { rem.employer = employer });
                refreshedRemittances.setAdjustmentsOnRemittanceList(adjustments);
                RemittanceBusiness.refreshBalances(refreshedRemittances);
                const currentRemittance = refreshedRemittances.find((rem) => rem.period.value === period);

                // get current remittance with details
                // set excludeRetroactiveAdjustments to false
                await RemittanceDetailService.initDetails(employer, period, currentRemittance, { excludeRetroactiveAdjustments: false });
                // load pointers
                for (const detail of (currentRemittance?.details?.all ?? [])) {
                    if(detail.employment){
                        await EmploymentService.loadEventsPointers(detail.employment);
                    }
                    if(detail.employment?.participation){
                        await ParticipationService.loadEventsPointers(detail.employment.participation);
                    }
                }

                setSummariesToShow(new Remittances( refreshedRemittances.filter((rem) => rem.period.value === period || periodInstance.dec(1, true).isSame(rem.period))) );
                setRemittances(refreshedRemittances);
                setEmployer(employer);
                setIsLoading(false);
            }
        }
        getDetails();
    }, [employerId]);

    useEffect(() => setIsLoading(false), [summariesToShow]);


    const triggerCalculations = async (shouldSaveAdjustment = false) => {
        RemittanceBusiness.calculate(remittance);
        await RemittanceBusiness.calculateCredit(remittance, remittances, shouldSaveAdjustment);
        const updatedRems = new Remittances(remittances.map((rem) =>
            rem.period.isSame(remittance.period) ? remittance : rem
        ));
        RemittanceBusiness.refreshBalances(updatedRems);
        setRemittances(updatedRems.all);
        const summaries = new Remittances(updatedRems.filter(
            (rem) =>
                rem.period.value === period ||
                new Period(period).dec(1, true).isSame(rem.period)
        ));
        setSummariesToShow(summaries);
    };

    const validateDetails = () => {
        remittance.details.forEach((det) =>
            RemittanceDetailBusiness.validate(det)
        );
    }

    const handleReset = () => {
        setIsLoading(true);
        setRemittances(prev => prev.map(rem => {
            if (rem.period.isSame(remittance.period)) {
                rem.contributions.reset();
                rem.erContribs = 0;
                rem.voluntary = 0;
                rem.details.resetAllContributionsAndEarnings();
                rem.details.appendMissingEarningTypes(
                    rem.employer.getActiveEarningTypes()
                );
                rem.details.forEach((det) =>
                    RemittanceDetailBusiness.validate(det)
                );
            }
            return rem;
        }));
        setFileName("");
        setIsDirty(true);
        addMessage(`Remittance was successfully reset${(remittance?.hasLinkedEvents?.() || remittance?.hasLinkedEmployments?.()) ? ` and linked ${getEventsAndEmploymentsMessage(remittance?.hasLinkedEvents?.(), remittance?.hasLinkedEmployments?.())} will be removed when you save` : ''}`, "success");
        setIsLoading(false);
    }

    const handleInspection = (details, fileName) => {
        setIsLoading(true);
        RemittanceService.inspectEarningUpload(remittance, details).then(
            (summary) => {
                setIsLoading(false);
                if (summary.length > 0) {
                    setInspectionPopUp(summary);
                    setUploadDetails({ details, fileName });
                } else {
                    handleUpload(details, fileName);
                }
            }
        );
    };

    const handleUpload = (details, fileName, uploadActions) => {
        setIsLoading(true);
        RemittanceService.uploadEarnings(remittance, details, uploadActions).then(
            async (summary) => {
                remittance.importedDate = moment();
                await triggerCalculations();
                setFileName(fileName);
                setIsDirty(true);
                setIsLoading(false);
                addMessage("Remittance was successfully uploaded", "success");
                setSummaryPopUp(summary);
                setUploadDetails(null);
            }
        );
    };

    const handleSave = async () => {
        setFileName("");
        setIsDirty(false);
        setIsLoading(true);
        await triggerCalculations(true);
        // Add the earnings upload policies when saving (if we don't want these changes added to the remittance, we can reset the remittance)
        await Promise.all(remittance.details.map((det) => 
            RemittanceService.applyEarningUploadPolicies(det, undefined, { commit: true, openRemittance: remittance, eventsSource: EVENT_SOURCE.FILE.key })
        ));
        remittance.shouldRecalculate = false;
        const hasLinkedEvents = remittance?.hasLinkedEvents?.();
        const hasLinkedEmployments = remittance?.hasLinkedEmployments?.();
        if((hasLinkedEvents || hasLinkedEmployments) && isResetEventConfirmed){
            await RemittanceService.removeLinkedEmployments(remittance);
            await RemittanceService.removeLinkedEvents(remittance);
        }
        await RemittanceDetailService.updateAll(remittance.details);
        await RemittanceService.updateRemittance(remittance);
        addMessage(`Remittance was successfully saved${(hasLinkedEvents || hasLinkedEmployments) && isResetEventConfirmed ? ` and linked ${getEventsAndEmploymentsMessage(hasLinkedEvents, hasLinkedEmployments)} were removed` : ''}`, "success");
        // Update the remittance in the list to reflect the changes
        const updatedRems = new Remittances(remittances.map((rem) =>
            rem.period.isSame(remittance.period) ? remittance : rem
        ));
        setRemittances(updatedRems.all);
        setIsResetEventConfirmed(false);
    };

    const handleCalculate = async () => {
        setIsLoading(true);
        setIsDirty(true);
        await triggerCalculations();
        addMessage("Remittance details calculated successfully", "success");
    }

    const handleConfirmCancel = (isAnswerYes) => {
        if (isAnswerYes) {
            RemittanceDetailService.invalidateCache();
            EmploymentService.invalidateCache();
        }
    };

    const handleSaveComment = () => {
        setIsLoading(true);
        RemittanceService.update(remittance, "cmt").then(() => {
            setRemittances((prevRems) =>
                prevRems.map((rem) => {
                    if (rem.period.value === period) {
                        rem.cmt = remittance.cmt;
                    }
                    return rem;
                })
            );
            setNewComment(false);
            addMessage("Remittance comment was successfully saved", "success");
            setIsLoading(false);
            return remittance;
        });
    };

    const handleError = (error) => {
        console.log(error);
        setMessage(error);
    };

    const handleMessageSelection = (row) => {
        if (!row || !row.message) return;
        const detail = row;

        var warning = (
            <Modal className="w-80 modal-bg-color" centered>
                <WarningTitle onHide={handleCancelWarningFixer} />
                {row.displayedMessages.map((message) => {
                    var fixer = message.fixer
                        ? cloneElement(message.fixer, {
                            remittanceDetail: detail,
                            messageParams: message.params,
                            params: {
                                periodEts: detail.period.timestampAtPeriodStart,
                                periodEndTs: detail.period.timestampAtPeriodEnd,
                            },
                            onCancel: handleCancelWarningFixer,
                            onSave: handleSaveWarningFixer,
                        })
                        : null; 

                    return (
                        <div key={message.code}>
                            <Message message={message} />
                            <div style={{margin: '5px'}}>{fixer}</div>
                        </div>
                    )
                })}
                <Row className="justify-content-center">
                    <Button
                        className="btn-primary"
                        onClick={handleCancelWarningFixer}
                    >
                        OK
                    </Button>
                </Row>
            </Modal>
        );

        setWarningPopUp(warning);
    };

    const handleTaskList = (row) => {

        if (row.outstandingTasksCount === 0) return;

        var warning = (
            <Modal className="w-40 modal-bg-color" centered>
                <WarningTitle title={'Outstanding Tasks (' + row.outstandingTasksCount + ')'} onHide={handleCloseTaskList} />
                <TaskList 
                    onHide={handleCloseTaskList}
                    employment={row.employment} 
                    participation={row.employment.participation}
                />
            </Modal>
        );

        setWarningPopUp(warning);
    };

    const handleEarningsChanged = async (cell) => {
        // Get RemittanceDetail instance of the row that was changed
        const rowDetail = cell.getRow().getData();
        await RemittanceService.applyEarningUploadPolicies(rowDetail, undefined, {commit: true, openRemittance: remittance, eventsSource: EVENT_SOURCE.FILE.key});
        cell.getTable().redraw(true);
        setIsDirty(true);
    };

    const handleVoluntaryChanged = (cell) => {
        cell.getTable().redraw(true);
        setIsDirty(true);
    };

    const handleCancelWarningFixer = () => setWarningPopUp("");

    const handleCloseTaskList = async (employment) => {
        setIsLoading(true);
        validateDetails();
        handleCancelWarningFixer();
        setIsLoading(false);
    }

    const handleSaveWarningFixer = (detail, promise) => {
        setIsLoading(true);
        return promise().then(() => {
            RemittanceDetailBusiness.calculate(detail);
            setWarningPopUp('');
            setIsLoading(false);
        })
    }

    const handleSelectEmployment = (row) => { 
        history.push(`/employer/${employerCode}/employment/${row.employment.keyValue}`);
    }
    
    const handleDownloadData = (tab) => {
        if (tab === "payments" && paymentsExport) 
            paymentsExport.download();
        else if ((tab === "contributionsAdjustments" || tab === "earningAdjustments") && adjustmentsExport)
            adjustmentsExport();
        else if (dedEarningsTableExport) 
            dedEarningsTableExport.download();
    };
    
    return (
        <>
            <Prompt
                when={isDirty}
                message="You have not saved the remittance. Do you want to exit this page?"
                onConfirm={handleConfirmCancel}
            />
            {remittance && !isLoading ? (
                <>
                    {isResetEventWarningOpen && <QuestionLarge title={"Reset remittance warning"}
                    yesLabel="Yes, remove them when I save"
                    noLabel="No, don't reset the remittance"
                        content={
                        <div>
                            <p style={{ color: "red" }}>
                            Resetting the remittance will also{" "}
                            <span
                                style={{ fontWeight: 700, textDecoration: "underline" }}
                            >
                                remove the following {getEventsAndEmploymentsMessage(remittance?.hasLinkedEvents?.(), remittance?.hasLinkedEmployments?.())}
                            </span>{" "}
                            created by this remittance{" "}
                            <span
                                style={{ fontWeight: 700, textDecoration: "underline" }}
                            >
                                when you save
                            </span>
                            :
                            </p>
                            {Boolean(remittance?.hasLinkedEvents?.()) && (
                            <>
                                <p>Events:</p>
                                <ul>
                                {remittance
                                    ?.getLinkedEvents?.()
                                    .map(
                                    (eventInfo) =>
                                        `${eventInfo.personName}:  ${eventInfo.eventStatus} (${eventInfo.eventDesc}) on ${eventInfo.eventEffDt}`
                                    )
                                    .map((item) => (
                                    <li key={item}>{item}</li>
                                    ))}
                                </ul>
                            </>
                            )}

                            {Boolean(remittance?.hasLinkedEmployments?.()) && (
                            <>
                                <p>Employments:</p>
                                <ul>
                                {remittance
                                    ?.getLinkedEmployments?.()
                                    .map(
                                    (employmentInfo) =>
                                        `${employmentInfo.personName}:  ${employmentInfo.employmentStatus} on ${employmentInfo.employmentEffDt}`
                                    )
                                    .map((item) => (
                                    <li key={item}>{item}</li>
                                    ))}
                                </ul>
                            </>
                            )}

                            <p style={{ marginTop: "2rem" }}>Do you want to proceed?</p>
                        </div>
                        }
                        onAnswer={(answer) => {
                            setIsResetEventWarningOpen(false);
                            if(answer){
                                setIsResetEventConfirmed(true);
                                handleReset();
                            }
                        }}
                    />}
                    <div className="d-flex flex-column h-100">
                        <Title
                            titleText={`Remittance Details - ${remittance.period.longDesc}`}
                            showBackButton
                        />
                        <Message message={message} />
                        {summariesToShow && (
                            <FieldSet className="mt-1 no-frame">
                                <RemittanceBalance
                                    remittances={summariesToShow}
                                    variant="displayOnly"
                                />
                            </FieldSet>
                        )}
                        <Tabs
                            initial={activeTab}
                            onChange={(tab) => setActiveTab(tab)}
                            className="mt-4"
                        >
                            <Tabs.Options>
                                <Row>
                                    {remittance.period.yearEnd && (
                                        <Button
                                            className="btn-secondary px-3"
                                            onClick={() =>
                                                setIsYECertificationOpen(true)
                                            }
                                        >
                                            Year End Certification
                                        </Button>
                                    )}
                                    <Button
                                        key="comment"
                                        className="btn-secondary px-3"
                                        onClick={() => setNewComment(true)}
                                    >
                                        Add Comment
                                    </Button>
                                    <ReportsMenu
                                        isDirty={isDirty}
                                        earningTypes={earningTypes}
                                        remittance={remittance}
                                        employer={employer}
                                    />
                                    <Button
                                        disabled={isDirty}
                                        className="btn-secondary px-3"
                                        onClick={() =>
                                            handleDownloadData(activeTab)
                                        }
                                    >
                                        Export
                                    </Button>
                                </Row>
                            </Tabs.Options>
                            <Tabs.Tab
                                name="contributions"
                                title="Contributions"
                            />
                            <Tabs.Tab name="earnings" title="Earnings" />
                            <Tabs.Tab name="summary" title="Totals" />
                            <Tabs.Tab name="payments" title="Payments">
                                <PaymentList
                                    remittances={remittances}
                                    employer={employer}
                                    period={remittance.period}
                                    ref={(ref) => setPaymentsExport(ref)}
                                    className="maxvh-55"
                                />
                            </Tabs.Tab>
                            <Tabs.Tab
                                name="contributionsAdjustments"
                                title="Contributions Adj."
                            >
                                <AdjustmentList
                                    activeTab={"contribution"}
                                    adjustments={remittance.adjustments}
                                    setExportFunction={setAdjustmentsExport}
                                    className="maxvh-55"
                                />
                            </Tabs.Tab>
                            <Tabs.Tab
                                name="earningAdjustments"
                                title="Earnings Adj."
                            >
                                <AdjustmentList
                                    activeTab={"earning"}
                                    adjustments={remittance.adjustments}
                                    setExportFunction={setAdjustmentsExport}
                                    className="maxvh-55"
                                />
                            </Tabs.Tab>
                            {remittance.period.yearEnd && (
                                <Tabs.Tab name="validation" title="Validations">
                                    <ValidationTable
                                        employer={employer}
                                        remittanceDetails={
                                            remittance.details.all
                                        }
                                        year={remittance.period.year}
                                    />
                                </Tabs.Tab>
                            )}
                            {["contributions", "earnings", "summary"].includes(
                                activeTab
                            ) && (
                                <DeductionsEarningsTable
                                    ref={(ref) =>
                                        setDedEarningsTableExport(ref)
                                    }
                                    view={activeTab}
                                    remittanceDetails={remittance.details}
                                    earningTypes={earningTypes}
                                    period={remittance.period}
                                    onSelectedEmployee={handleSelectEmployment}
                                    onSelectedMessage={handleMessageSelection}
                                    onSelectedTaskList= {handleTaskList}
                                    onDetailChange={handleEarningsChanged}
                                    onVoluntaryChanged={handleVoluntaryChanged}
                                    filterEmptyCols={!remittance.isNew()}
                                    editable={!remittance.validated}
                                />
                            )}
                        </Tabs>
                        {!remittance.validated && (
                            <Row className="mt-3">
                                <Col right>
                                    <Row>
                                        <Button
                                            key="reset"
                                            className="d-flex justify-content-center align-items-center ml-3 px-3"
                                            onClick={() => {
                                                if(remittance?.hasLinkedEvents?.() || remittance?.hasLinkedEmployments?.()){
                                                    setIsResetEventConfirmed(false);
                                                    setIsResetEventWarningOpen(true);
                                                } else {
                                                    handleReset();
                                                }
                                            }}
                                        >
                                            Reset
                                            <Icon
                                                icon="undo"
                                                className="text-white ml-2"
                                                tooltip-left
                                                large
                                            />
                                        </Button>
                                        <EarningsImport
                                            className="ml-3"
                                            earningTypes={earningTypes}
                                            onUpload={handleInspection}
                                            onError={handleError}
                                            fileName={fileName}
                                        />
                                        <Button
                                            key="calculate"
                                            className="d-flex justify-content-center align-items-center ml-3 px-3"
                                            onClick={handleCalculate}
                                        >
                                            Calculate
                                            <Icon
                                                tooltip="This will only calculate, but not save"
                                                icon="calculator"
                                                className="text-white ml-2"
                                                tooltip-left
                                                large
                                            />
                                        </Button>
                                        <Button
                                            key="save"
                                            className="d-flex justify-content-center align-items-center ml-3 px-3"
                                            onClick={handleSave}
                                        >
                                            Save
                                            <Icon
                                                tooltip="This will calculate and save"
                                                icon="save"
                                                className="text-white ml-2"
                                                tooltip-left
                                                large
                                            />
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                    {newComment && (
                        <Modal
                            title=""
                            className="w-40 modal-bg-color"
                            onHide={() => setNewComment(false)}
                        >
                            <Form
                                className="h-100"
                                id="CommentForm"
                                data={remittance}
                                onSave={handleSaveComment}
                                onCancel={() => setNewComment(false)}
                            >
                                <Row>
                                    <EInput
                                        instance={remittance}
                                        name="cmt"
                                        variant="textarea"
                                        cn="col"
                                        maxLength="150"
                                        hint="Must be less than 150 characters"
                                    />
                                </Row>
                            </Form>
                        </Modal>
                    )}
                    {summaryPopUp && (
                        <>
                            <Modal className="h-100 w-80 modal-bg-color">
                                <Title
                                    title="Upload Summary"
                                    onHide={() => setSummaryPopUp(null)}
                                />
                                <SummaryTable
                                    summary={summaryPopUp}
                                    hideLabel={"Ok"}
                                    onHide={() => setSummaryPopUp(null)}
                                />
                            </Modal>
                        </>
                    )}
                    {inspectionPopUp && (
                        <>
                            <Modal className="w-80 modal-bg-color">
                                <PreUpload
                                    summary={inspectionPopUp}
                                    confirmLabel="Confirm Upload"
                                    onConfirm={(uploadActions) =>
                                        handleUpload(
                                            uploadDetails.details,
                                            uploadDetails.fileName,
                                            uploadActions
                                        )
                                    }
                                    onHide={() => setInspectionPopUp(null)}
                                />
                            </Modal>
                        </>
                    )}
                    {warningPopUp}
                    {isYECertificationOpen && (
                        <YECertification
                            onClose={() => setIsYECertificationOpen(false)}
                            remittance={remittance}
                        />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default RemittanceDetailsPage;
