import ImportMessage from './ImportMessage';

/**
 * Central factory for creating import messages
 */
class ImportMessageFactory {
    templates = new Map();

    constructor() {
        // Register default messages
        this.register('INVALID_VALUE', 'Invalid value provided for {field}: {value}', ImportMessage.TYPE.ERROR);
        this.register('UNKNOWN_ERROR', 'An unknown error occurred.', ImportMessage.TYPE.ERROR);
        this.register('OK', 'No issues found.', ImportMessage.TYPE.INFO);
    }

    /**
     * Register a new message template
     */
    register(key, messageTemplate, type = ImportMessage.TYPE.INFO) {
        if (this.templates.has(key)) { throw new Error(`Template already registered for key: ${key}`) }

        this.templates.set(key, {
            messageTemplate,
            type
        });
    }

    /**
     * Create a message from a registered template
     * @param {string} key - Template key
     * @param {Object} params - Parameters to replace in template
     * @param {Object} subList - Optional sublist of data
     * @param {Object} focus - Optional focus object to highlight in the UI
     */
    create(key, params = {}, subList = null, focus = null) {
        const template = this.templates.get(key);
        if (!template) { throw new Error(`Template not found for key: ${key}`) }

        let formattedMessage = template.messageTemplate;
        Object.entries(params).forEach(([key, value]) => {
            formattedMessage = formattedMessage.replace(`{${key}}`, value);
        });

        return new ImportMessage(key, formattedMessage, template.type, subList, focus);
    }

    /**
     * Create an unregistered message
     * @param {string} message - Message text
     * @param {string} type - Message type
     * @param {Object} subList - Optional sublist of data
     * @param {Object} focus - Optional focus object to highlight in the UI 
     */
    createUnregistered(message, type = ImportMessage.TYPE.ERROR, subList = null, focus = null) {
        return new ImportMessage('UNREGISTERED', message, type, subList, focus);
    }

    /**
     * Get a registered template by key
     * @param {string} key - Template key
     * @returns {Object} - Template object
     */
    getTemplateByKey(key) {
        return this.templates.get(key);
    }
}

export default ImportMessageFactory; 