/**
 * Represents the options available for import operations
 */
class ImportOptions {
    /**
     * Creates a new ImportOptions instance
     * @param {Object} options - The options object
     * @param {((values: {processed: number; total: number;}) => void) | undefined} [options.loaderController] - Function to update loader progress
     * @param {boolean} [options.commit=false] - Whether to commit changes to the database
     * @throws {Error} If invalid options are provided
     */
    constructor(options = {}) {
        // Validate options before assigning
        ImportOptions.validate(options);
        
        // Assign validated options
        this.loaderController = options.loaderController;
        this.commit = options.commit ?? false;
    }

    /**
     * Function to control loader progress updates
     * @type {((values: {processed: number; total: number;}) => void) | undefined}
     */
    loaderController;

    /**
     * Whether to commit changes to the database
     * @type {boolean}
     */
    commit;



    /**
     * Validates that the options object contains only valid properties and types
     * @param {Object} options - The options object to validate
     * @throws {Error} If invalid options are provided
     */
    static validate(options) {
        // Ensure options is an object
        if (options !== null && typeof options !== 'object' && !Array.isArray(options)) {
            throw new Error('Import options must be an object');
        }

        // Check for invalid keys
        const validKeys = ['loaderController', 'commit'];
        const invalidKeys = Object.keys(options).filter(key => !validKeys.includes(key));
        
        if (invalidKeys.length > 0) {
            throw new Error(`Invalid import options provided: ${invalidKeys.join(', ')}`);
        }

        // Validate loaderController type
        if (options.loaderController !== undefined) {
            if (typeof options.loaderController !== 'function') {
                throw new Error('loaderController must be a function');
            }
        }

        // Validate commit type
        if (options.commit !== undefined) {
            if (typeof options.commit !== 'boolean') {
                throw new Error('commit must be a boolean');
            }
        }
    }
}

export default ImportOptions;
