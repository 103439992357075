import moment from "moment";
import { parseExcelDate } from "../../../framework/utils/helper";
import ImportFieldDefinition from "../../../entities/import/ImportFieldDefinition";
import { isValueUndefined, isValidSIN, isValidMoment, cleanseSIN } from "../../../framework/utils/helper";
import ImportFieldValidationResponse from "../../../entities/import/ImportFieldValidationResponse";

export default [
    new ImportFieldDefinition('sin', 'SIN', {
        format: (value) => cleanseSIN(value),
        validation: (value) => {
            if (isValueUndefined(value)) {
                return new ImportFieldValidationResponse(false, 'MISSING_SIN');
            }
            return new ImportFieldValidationResponse(isValidSIN(value), 'INVALID_SIN', { sin: value });
        },
        required: true
    }),
    new ImportFieldDefinition('joinDate', 'Join Date', {
        aliases: ['joineddate', 'ppeffectivedate'],
        format: (value) => isValueUndefined(value) ? null : moment(parseExcelDate(value)),
        validation: (value) => 
            new ImportFieldValidationResponse(!isValueUndefined(value) && isValidMoment(value), 'INVALID_JOIN_DATE', { joinDate: value }),
        required: true
    }),
    new ImportFieldDefinition('mercerKey', 'Mercer Key', {  
        validation: (value) =>
            new ImportFieldValidationResponse(!isValueUndefined(value), 'MISSING_MERCER_KEY'),
        required: true
    })
];