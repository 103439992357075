/**
 * Business logic for importing mercer keys
 */

import moment from "moment";
import Import from "../../../framework/infra/business/Import";
import ImportMessage from "../../../entities/import/ImportMessage";
import ImportMessageFactory from "../../../entities/import/ImportMessageFactory";
import ImportRowDetail from "../../../entities/import/ImportRowDetail";
import PersonService from "../../../services/person/PersonService";
import MembershipService from "../../../services/membership/MembershipService";
import ParticipationService from "../../../services/membership/ParticipationService";
import MercerKeyImportSchema from "./MercerKeyImportSchema";

class MercerKeyImportBusiness extends Import {

    static TEMPLATE_NAME = 'Mercer-Key-Upload-template.xlsx';

    constructor() {

        const messageFactory = new ImportMessageFactory();

        // Register all messages when class is loaded
        messageFactory.register('MISSING_SIN', 'No SIN provided.', ImportMessage.TYPE.ERROR );
        messageFactory.register('DUPLICATE_SIN', 'SIN {sin} already appears in this file', ImportMessage.TYPE.ERROR );
        messageFactory.register('INVALID_SIN', 'Invalid SIN number {sin}.', ImportMessage.TYPE.ERROR );
        messageFactory.register('INVALID_JOIN_DATE', 'Invalid join date. {joinDate}', ImportMessage.TYPE.ERROR );
        messageFactory.register('MISSING_MERCER_KEY', 'A mercer key is required.', ImportMessage.TYPE.ERROR );
        messageFactory.register('MISSING_PERSON', 'Person not found.', ImportMessage.TYPE.ERROR );
        messageFactory.register('MISSING_MEMBERSHIP', 'Membership not found.', ImportMessage.TYPE.ERROR );
        messageFactory.register('MISSING_PARTICIPATION', 'Participation not found.', ImportMessage.TYPE.ERROR );
        messageFactory.register('WILL_UPDATE', 'Will update participation with mercer key "{mercerKey}".', ImportMessage.TYPE.INFO );
        messageFactory.register('UPDATED', 'Participation updated with mercer key "{mercerKey}".', ImportMessage.TYPE.INFO );

        super({
            templateName: MercerKeyImportBusiness.TEMPLATE_NAME,
            rowSchema: MercerKeyImportSchema,
            importMessageFactory: messageFactory,
        });
    }

    /**
     * Initialize the import context, a global context for the import
     * @param {ImportOptions} options - The import options
     * @returns {Object} - The import context
     */
    async _initImportContext(options) {

        const importContext = {
            processedSins: []
        }

        return importContext;
    }

    /**
     * Is called by the _processImport method to process each row in the file
     * Main logic for processing each row is implemented here
     * @param {Object} importContext - The global import context
     * @param {number} rowIndex - The index of the row in the file
     * @param {Array<string>} row - The row data
     * @param {ImportRowDetail} rowDetail - The row detail for results
     * @param {ImportOptions} options - The import options
     * @returns {Promise<ImportRowDetail>} - The processed row detail
     */
    async _processRow(importContext, rowIndex, row, rowDetail, options) {
        try {
            // Check for duplicate SIN
            if (importContext.processedSins.includes(row.sin)) {
                return rowDetail.addMessage(this.importMessageFactory.create('DUPLICATE_SIN', { sin: row.sin }));
            }

            // Get person
            const person = await PersonService.getPersonBySin(row.sin);
            if (!person) {
                return rowDetail.addMessage(this.importMessageFactory.create('MISSING_PERSON'));
            }

            // Get membership
            const membership = await MembershipService.getMembership(person.id);
            if (!membership) {
                return rowDetail.addMessage(this.importMessageFactory.create('MISSING_MEMBERSHIP'));
            }

            // Find participation with join date
            const participation = membership.participations.find(pp => 
                moment(pp.joinDt).isSame(row.joinDate)
            );
            if (!participation) {
                return rowDetail.addMessage(this.importMessageFactory.create('MISSING_PARTICIPATION'));
            }

            const stringMercerKey = String(row.mercerKey);  
            if (participation.mercerKey === stringMercerKey) {
                return rowDetail; // no change
            }

            // Update participation with mercer key
            participation.mercerKey = stringMercerKey;
            
            if (options.commit) {
                await ParticipationService.updateParticipation(participation);
                rowDetail.addMessage(this.importMessageFactory.create('UPDATED', { mercerKey: row.mercerKey }));
            } else {
                rowDetail.addMessage(this.importMessageFactory.create('WILL_UPDATE', { mercerKey: row.mercerKey }));
            }

            // Track processed SIN
            importContext.processedSins.push(row.sin);

            return rowDetail;
        } catch (error) {
            console.error('Error processing row:', error);
            return rowDetail.addMessage(this.importMessageFactory.create('UNKNOWN_ERROR'));
        }
    }
}

export default new MercerKeyImportBusiness();