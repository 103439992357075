import { isValueUndefined, isValidDate, today } from "./helper";
import { toEvent } from "./formating";

/**
 * Assign simple properties to an entity
 * @param {Object} entity - The entity
 * @param {Object} row - The row
 * @param {Array<string>} propList - The property list
 * @param {Array<ImportFieldDefinition>} rowSchema - The row schema
 * @param {Changes} changes - The changes
 * @returns {Changes} - The changes
 */
function assignSimpleProps(entity, row, propList = [], rowSchema, changes) {
    propList.forEach(prop => {
        if (!isValueUndefined(row[prop]) && entity[prop] !== row[prop]) {
            //get the title of the property based on import schema
            const title = rowSchema.find(field => field.name === prop).title;
            changes.logChange(title, entity[prop], row[prop]);
            entity[prop] = row[prop];
        }
    });
    return changes;
}

/**
 * Assign historical properties to an entity using the provided data and value
 * @param {Object} entity - The entity
 * @param {Object} row - The row
 * @param {Array<string>} propList - The property list
 * @param {Array<ImportFieldDefinition>} rowSchema - The row schema
 * @param {Changes} changes - The changes
 * @returns {Changes} - The changes
 */
function assignHistoricalProps(entity, row, propList = [], rowSchema, changes) {

    propList.forEach(prop => {
        if (!isValueUndefined(row[prop])) {
            const newVal = row[prop];
            const newDate = new Date(getHistoricalDate(entity, row, prop));

            let oldValueAtProvidedDate = entity[prop + 'History'].getAt(newDate.valueOf())?.value;

            // for enum values like workSchedule, we need to access by key
            if(Object.hasOwn(oldValueAtProvidedDate, 'key')) oldValueAtProvidedDate =  oldValueAtProvidedDate.key;

            if(newVal !== oldValueAtProvidedDate) {
                const histItem = entity[prop + 'History'].create();
                histItem.value = newVal;
                histItem.ets = newDate.valueOf();
                histItem.cmt = '';

                changes.logChange(
                    rowSchema.find(field => field.name === prop).title, 
                    `${ isValueUndefined(oldValueAtProvidedDate) ? 'N/A' : oldValueAtProvidedDate}`,
                    `${newVal} @ ${histItem.effDt}`
                );

                entity[prop + 'History'].addNewHistoricalItem(histItem);
            }
        }
    });
    return changes;
}

/**
 * Detect and logs event changes in the change logger
 * @param {Event | undefined} oldEvent - The old event
 * @param {Event | undefined} newEvent - The new event
 * @param {ImportChangeLogger} changeLogger - The change logger
 * @returns {ImportChangeLogger} - The change logger
 */
function detectEventChanges(oldEvent = {}, newEvent = {}, changeLogger, options = { titleOverride: null }) {
    if (oldEvent.effDt !== newEvent.effDt) {
        changeLogger.logChange((options.titleOverride || newEvent.desc) + ' Effective Date', oldEvent.effDt, newEvent.effDt);
    }
    if (!isValueUndefined(newEvent.cmt) && oldEvent.cmt !== newEvent.cmt) {
        changeLogger.logChange((options.titleOverride || newEvent.desc) + ' Comment', oldEvent.cmt, newEvent.cmt);
    }
    return changeLogger;
}

/**
 * Get a list of events based on the eventList (code + property)
 * @param {Object} entity - The entity
 * @param {Object} row - The row
 * @param {Array<{ code: string, prop: string }>} eventList - The event list
 * @param {string} eventSource - The event source
 * @returns {{ [prop: string]: Event }} - The events
 */
function getEvents(entity, row, eventList, eventSource) {
    const events = {};
    eventList.forEach(eventDef => {
        if (!isValueUndefined(row[eventDef.prop])) {
            const event = toEvent(entity, eventDef.code, row[eventDef.prop], (row[eventDef.prop + 'Comment'] ?? '').toString(), eventSource);
            events[eventDef.prop] = event;
        }
    });

    return events;
}

/**
 * Update or add an event to an entity, if the event can't be updated, add a warning message to the row detail
 * @param {Object} entity - The entity to add the event to
 * @param {Event | undefined} previousEvent - The previous event (if any)
 * @param {Event | undefined} newEvent - The new event 
 * @param {Changes} changes - The change logger
 * @param {Object { canUpdate: boolean, updateWarning: ImportWarningMessage, rowDetail: ImportRowDetail, titleOverride: string, eventParams: { openEmployment: boolean } }} options - The options
 */
function updateOrAddEvent(entity, previousEvent, newEvent, changes, options = { 
    canUpdate: true, 
    updateWarning: undefined, 
    rowDetail: undefined,
    titleOverride: undefined,
    eventParams: {
        openEmployment: undefined,
    }
}) {
    if (isValueUndefined(newEvent)) return;
    if (isValueUndefined(previousEvent)) {
        detectEventChanges(previousEvent, newEvent, changes, options);
        entity.addEvent(newEvent, options.eventParams);
        return;
    }  
    if (previousEvent.effDt !== newEvent.effDt) {
        if (options.canUpdate) {
            changes.logChange((options.titleOverride || newEvent.desc) + ' Effective Date', previousEvent?.effDt, newEvent.effDt);
            previousEvent.effDt = newEvent.effDt;
        } else {
            options.rowDetail.addMessage(options.updateWarning);
        }
    }
    if (!isValueUndefined(newEvent.cmt) && previousEvent.cmt !== newEvent.cmt) {
        changes.logChange((options.titleOverride || newEvent.desc) + ' Comment', previousEvent?.cmt, newEvent.cmt);
        previousEvent.cmt = newEvent.cmt;
    }
}

/**
 * Get the right historical date for a property, if the date is provided in the row, use it, if not, use the hire date from the row or the employment
 * @param {Object} employment - The employment
 * @param {Object} row - The row
 * @param {string} property - The property
 * @returns {string} - The historical date
 */
function getHistoricalDate(entity, row, property) {
	if(isValidDate(row[property + 'Date'])) {
		return row[property + 'Date']; //Date from import
	} else if (isValidDate(row.hiredDate)) {
		return entity.hiredDate; //Hire date from import
	} else if (entity.hiredDate) { 
		return entity.hiredDate; //Hire date in DB
	} else return today(); //Default to today
}

export { assignSimpleProps, assignHistoricalProps, detectEventChanges, getEvents, updateOrAddEvent };