import React from 'react'
import {Tab, Tabs} from 'react-bootstrap';

import ReportLayout from './ReportLayout'
import { Table} from '../../../framework/controls';

/**
 * Report Layout with Tabs
 * 
 * Props:
 * - `tabsConfig`: config of all the tabs. Type `{ [key]: {tabName: string; tabTitle: string; tableHeaders: string[]; tableHeadersProps: object; tabGroupHeaders?: string[] | undefined; tabSubHeaders?: string[] | undefined; exportColumns: object[]} }` (see type of `exportColumns` in `Tabulator`)
 * 
 * See also ReportLayout props
 * 
 * State:
 * - `tabsData`: expected to be set by the component extending this class, in `execQuery()`
 * 
 * See also ReportLayout state
 */
export default class ReportLayoutTabs extends ReportLayout {
    constructor(props) {
        super(props);
        const customStateInit = {activeTab: this.tabsConfig?.[0]?.tabName };
        if(!this.state) {
            this.state = {...customStateInit};
        } else {
            this.setState({...customStateInit})
        }
    }

    /**
     * Render the view for all tabs (one table in each tab)
     * @param {any[]} filteredData 
     * @param {Table.Headers} headers 
     * @param {object | undefined} exportColumns 
     * @param {string | undefined} exportFileName 
     * @returns 
     */
    viewTable(filteredData, headers, exportColumns, exportFileName){
        if(!this.tabsConfig?.length) {
            try{
                return super.viewTable(filteredData, headers, exportColumns, (exportFileName || this.props.exportFileName));
            } catch(err){
                console.error('ReportLayoutTabs viewTable error in super.viewTable call', err)
            }
            return null;
        }

        /**
         * Get the table headers (for a tab's table).
         * 
         * Flattens the grouped headers.
         * @param {object} tabConfig 
         * @returns {Table.Headers}
         */
        const getTableHeaders = (tabConfig) => {
            const headers = new Table.Headers(this.entity, tabConfig.tableHeaders, tabConfig.tableHeadersProps)
            if (tabConfig.tabGroupHeaders) {
                tabConfig.tabGroupHeaders.forEach(path => {
                    const header = headers[path] = new Table.Header(this.entity, path, tabConfig.tableHeadersProps[path]);
                    /** @type {string[]} */
                    const columns = tabConfig.tabSubHeaders?.reduce((cols, sub) => {
                        cols.push(path + `.${sub}`)
                        return cols
                    }, []) ?? [];
                    header.headers = new Table.Headers(this.subEntity, columns) 
                })
            };
            return headers;
        }

        /**
         * Get the exported headers.
         * @param {object} tabConfig 
         * @returns {Table.Headers}
         */
        const getTabExportColumns = (tabConfig) => {
            if(!tabConfig.exportColumns) return {};
            // generate full headers
            const headers = new Table.Headers(this.entity, Object.keys(tabConfig.exportColumns), {...tabConfig.exportColumns}) //getTableHeaders(tabConfig);
            // headers for export
            const exportColumns = {...tabConfig.exportColumns};

            Object.entries(exportColumns).forEach(([exportColumnKey, exportColumnConfig]) => {
                const header = headers[exportColumnKey];
                if(!exportColumnConfig.name) {
                    exportColumnConfig.name = header?.name || exportColumnKey;
                }
                if(!exportColumnConfig.title && (tabConfig.tableHeadersProps?.[exportColumnKey]?.overrideText || header?.title)) {
                    exportColumnConfig.title = tabConfig.tableHeadersProps?.[exportColumnKey]?.overrideText || header.title;
                }
                if(!exportColumnConfig.format && !exportColumnConfig.disableFormat && header?.format) {
                    exportColumnConfig.format = header.format;
                }
            });
            return exportColumns;
        }

        try{
          return <div style={{marginTop: '10px'}}>
            <Tabs
                activeKey={this.state.activeTab || this.tabsConfig?.[0]?.tabName}
                onSelect={(k) => {
                    this.setState({activeTab: k})
                }}
                className="mb-3"
                >
                {this.tabsConfig.map(tabConfig => (<Tab
                    key={tabConfig.tabName}
                    eventKey={tabConfig.tabName}
                    title={tabConfig.tabTitle}
                >
                    {super.viewTable(filteredData, getTableHeaders(tabConfig), getTabExportColumns(tabConfig), `${(exportFileName || this.props.exportFileName)}-${tabConfig.tabName}`)}
                </Tab>))}
            </Tabs>
            </div>;
        } catch(err){
            console.error('ReportLayoutTabs viewTable error', err)
        }
        return null;
    }

    /**
     * Get the data of the current tab
     * @param {string} tabKey the tab key
     * @returns {any[] | undefined}
     */
    getTabData(tabKey){
        return this.state?.tabsData?.[tabKey];
    }

    /**
     * 
     * @param data not used
     * @param {string | undefined} tabKey the tab key. Optional. Default: current tab key.
     * @returns The filtered data of the requested tab, or the current tab, or the default tab
     */
    filterDataWithFiltersInstances(data, tabKey) {
        const selectedKey = tabKey || this.state.activeTab || this.tabsConfig?.[0]?.tabName;
        const tabData = this.getTabData(selectedKey);
        return super.filterDataWithFiltersInstances(tabData)
    }
}