/**
 * Business logic for importing data
 */
import { promisify } from "util"
import { ExcelRenderer } from 'react-excel-renderer'
import { Excel } from '../../utils';
import { cleanHeaderRow } from '../../utils/excelImportHelper';
import { toCleanString } from '../../utils/formating';
import ImportResponse from '../../../entities/import/ImportResponse';
import ImportRowDetail from '../../../entities/import/ImportRowDetail';
import ImportOptions from '../../../entities/import/ImportOptions';

import { 
    PersonService, 
    EmployerService, 
    EmploymentService, 
    MembershipService, 
    ParticipationService 
} from '../../../services';

export default class Import {

    constructor({ templateName, rowSchema, importMessageFactory }) {
        this.templateName = templateName;
        this.rowSchema = rowSchema;
        this.importMessageFactory = importMessageFactory;
    }

    /**
     * Name of the template to be used for the import
     * @type {string}
     */
    templateName;

    /**
     * Import row schema to be used for the import
     * @type {Array<ImportFieldDefinition>}
     */
    rowSchema;

    /**
     * React State Setter for the loader
     * @type {Function}
     */
    loaderController;

    /**
     * Import message factory
     * @type {ImportMessageFactory}
     */
    importMessageFactory;

    /**
     * Clear all service caches
     */
    clearCache = () => {
        PersonService.invalidateCache();
        EmployerService.invalidateCache();
        EmploymentService.invalidateCache();
        MembershipService.invalidateCache();
        ParticipationService.invalidateCache();
    }

    /**
     * Main import method that handles validation and delegates to _processImport
     * @param {Object} file - The file object containing header and data
     * @param {ImportOptions} options - Optional parameters for import
     * @returns {ImportResponse}
     */
    async import(file, options = new ImportOptions()) {

        //assignments directly mutate cached data, so we need to clear the cache each time
        this.clearCache();

        if (options.loaderController) {
            this.loaderController = options.loaderController;
        }

        const fileData = (await promisify(ExcelRenderer)(file)).rows;
        //pop the header row
        const header = fileData.shift();

        //validate that all the required fields are present
        const missingFields = this._validateHeader(header);

        if (missingFields.length > 0) {
            return new ImportResponse([], [`Missing required fields: ${missingFields.join(', ')}`]);
        }

        //get the header mappings, this will map the header to the field name
        const headerMapping = this._getHeaderMappings(header);

        //initialize the import context
        const importContext = await this._initImportContext(options);

        return this._processImport(importContext, fileData, headerMapping, options);
    }

    async _initImportContext(options) {
        throw new Error('_initImportContext must be implemented by child class');
    }

    /**
     * Protected method to be implemented by child classes to process a single row
     * @protected
     * @param {Object} importContext - The global import context
     * @param {number} rowIndex - The current row index
     * @param {Object} row - The mapped row data
     * @returns {Promise<ImportRowDetail>} The processed row detail
     */
    async _processRow(importContext, rowIndex, row) {
        throw new Error('_processRow must be implemented by child class');
    }

    /**
     * Main process import method that handles the import logic
     * @protected
     * @param {Object} importContext - The global import context
     * @param {Array<Array<string>>} fileData - The file data to process
     * @param {Array<{name: string, index: number}>} headerMapping - Header to column index mapping
     * @param {Object} options - Import options
     * @returns {Promise<ImportResponse>}
     */
    async _processImport(importContext, fileData, headerMapping, options) {
        const errors = [];
        
        const rowDetails = await this.#iterateFileData(fileData, async (rowIndex, row) => {
            try {
                const rowDetail = new ImportRowDetail(rowIndex, `Row ${rowIndex}`);

                // Map the row data using existing helper and formatters
                const mappedRow = this.#getRowBasedOnHeaderMapping(headerMapping, row);

                //for each field, check for validation rules
                for (const field of this.rowSchema) {
                    if (field.validation) {
                        const validationResponse = field.validate(mappedRow[field.name]);
                        if (!validationResponse.isValid) {
                            rowDetail.addMessage(this.importMessageFactory.create(validationResponse.errorKey, validationResponse.errorParams));
                        }
                    }
                }

                const processedRow = await this._processRow(importContext, rowIndex, mappedRow, rowDetail, options);

                if (!processedRow) {
                    throw new Error('No row was returned from _processRow');
                }

                //if the row has no errors, warnings, or info, add an OK message
                if (!processedRow.hasMessages) {
                    processedRow.addMessage(this.importMessageFactory.create('OK'));
                }

                return processedRow;
            } catch (error) {
                console.log('error', error);
                errors.push(`Error processing row ${rowIndex}: ${error.message}`);
                return null;
            }
        });

        return new ImportResponse(rowDetails, errors);
    }

    getTemplate() {
        const excel = new Excel(this.templateName)
        const sheet = excel.workBook.addWorksheet();
        sheet.name = 'Import Template';
        const headers = this.rowSchema.map(field => field.title)
        excel.addCustomDataRows(sheet, [{ row: headers, isBold: true }])
        excel.download()
    }

    /**
     * Get the import message factory
     * @returns {ImportMessageFactory}
     */
    getImportMessageFactory() {
        return this.importMessageFactory;
    }

    /**
     * Validate the header for required fields, based on configured name or aliases
     * Returns an array of missing fields
     * @param {Array<string>} header 
     * @returns {Array<string>}
     */
    _validateHeader(header = []) {
        // get the required fields, each required fields can have an alias
        // in the end, we want to return the title of the missing fields
        const requiredFields = this.rowSchema.filter(x=> x.required) 

        // key/value of field name and possible aliases
        const fieldAndAliases = requiredFields.map(x=> ({requiredField: x, aliases: cleanHeaderRow([...x.aliases, x.title, x.name])}))

        const normalizedHeader = cleanHeaderRow(header)

        // get the missing fields
        const missingFields = fieldAndAliases.filter(x=> !normalizedHeader.find(y=> x.aliases.includes(y)))

        return missingFields.map(x=> x.requiredField.title)
    }

    /**
     * Get the header mappings for the fields, returns an array of objects with the field name and the header index
     * @param {Array<string>} header 
     * @returns {Array<{name: string, index: number}>}
     */
    _getHeaderMappings(header) {
        const normalizedHeader = cleanHeaderRow(header)
        return this.rowSchema.map(x=> {
            const possibleNames = cleanHeaderRow([x.name, x.title, ...(x.aliases || [])])
            const index = normalizedHeader.findIndex(h => possibleNames.includes(h));
            return {name: x.name, index}
        })
    }

    /**
     * Get the row based on the header mapping
     * @param {Array<{name: string, index: number}>} headerMapping 
     * @param {Array<string>} row 
     * @returns {Object<string, string>}
     */
    #getRowBasedOnHeaderMapping(headerMapping, row) {
        return headerMapping.reduce((acc, x)=> {
            acc[x.name] = this.rowSchema.find(y=> y.name === x.name).format(toCleanString(row[x.index]))
            return acc
        }, {})
    }

    /**
     * Iterates through file data, updates loader, and processes rows
     * @param {Array<Array<string>>} fileData - Raw file data rows
     * @param {Array<{name: string, index: number}>} headerMapping - Header to column index mapping
     * @param {Function} rowProcessor - Async function to process each row (receives rowIndex and mappedRow)
     * @returns {Promise<Array<ImportRowDetail>>} Array of row details
     */
    async #iterateFileData(fileData, rowProcessor) {

        //sanitize the file data, remove empty rows
        const sanitizedFileData = fileData.filter(x=> x.length > 0);

        const total = sanitizedFileData.length;
        const rowDetails = [];
        
        for (let rowIndex = 0; rowIndex < sanitizedFileData.length; rowIndex++) {
            // Update loader if controller exists
            if (this.loaderController) {
                this.loaderController({
                    processed: rowIndex + 1,
                    total
                });
            }
            
            // Process the row with the provided function and collect the result, +2 because the row index is 0 based and we skip the header row
            const rowDetail = await rowProcessor(rowIndex + 2, sanitizedFileData[rowIndex]);
            if (rowDetail) {
                rowDetails.push(rowDetail);
            }
        }

        return rowDetails;
    }

}