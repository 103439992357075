import { Ref, Definition } from '../../framework/infra';

/**
 * Report class to export the import results to excel
 */
class Report extends Ref {

	/**
	 * Get the data for the report
	 * @param {Array<ImportRowDetail>} details - The details to export
	 * @returns {Array<Report>} - The data for the report
	 */
    static getData(details = []) {
        return details.reduce((rows, row) => {
			const reportRow = new Report({ 
				rowId: row.rowId, 
				message: row.toString(), 
				severity: row.severityLabel,
			});
			rows.push(reportRow);
            return rows;
        }, [])
    }
    
    static definitions = {
        rowId: { type: Definition.types.NUMBER, text: 'Row Id' },
		message: { type: Definition.types.STRING, text: 'Message'},
        severity: { type: Definition.types.STRING, text: 'Severity' },
    }
}

export default Report;